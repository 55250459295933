import React from "react";
import { Redirect } from "react-router-dom";

function RedirectGuard({ children, redirect }) {
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return children;
}

export default RedirectGuard;
