import React from "react";
import styled from "styled-components/macro";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link as MuiLink,
} from "@material-ui/core";

import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";

import { makeStyles, withStyles } from "@material-ui/core/styles";

const CustomTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Link = styled(MuiLink)`
  overflow-wrap: anywhere;
`;

const useStyles = makeStyles((theme) => ({
  customCell: {
    textTransform: "capitalize",
    display: "table-cell",
    flexDirection: "row",
  },
  subName: {
    fontSize: "10px",
    color: theme.palette.secondary.main,
    alignSelf: "flex-end",
    margin: "0 5px",
  },
}));

function DetailsTable({ data, regions, campaign }) {
  const classes = useStyles();
  const regionsTemp = regions
    ?.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [])
    .map((r) => r.name);
  return data ? (
    <Table size="small">
      <TableBody>
        <CustomTableRow>
          <CustomTableCell component="th" scope="row">
            <b>Campaign</b>
          </CustomTableCell>
          <CustomTableCell className={classes.customCell}>
            {campaign?.name || ""}
          </CustomTableCell>
          <CustomTableCell>
            {/*<IconButton m={2} size="small" aria-label="View">*/}
            {/*  <EditIcon color="primary" onClick={onEditCampaign} />*/}
            {/*</IconButton>*/}
          </CustomTableCell>
        </CustomTableRow>

        <CustomTableRow>
          <CustomTableCell component="th" scope="row">
            <b>Product</b>
          </CustomTableCell>
          <CustomTableCell className={classes.customCell}>
            {data.product?.name}
          </CustomTableCell>
          <CustomTableCell />
        </CustomTableRow>

        <CustomTableRow>
          <CustomTableCell component="th" scope="row">
            <b>Category</b>
          </CustomTableCell>
          <CustomTableCell className={classes.customCell}>
            {campaign?.category?.name || ""}
          </CustomTableCell>
          <CustomTableCell />
        </CustomTableRow>

        <CustomTableRow>
          <CustomTableCell component="th" scope="row">
            <b>Brand</b>
          </CustomTableCell>
          <CustomTableCell className={classes.customCell}>
            {campaign?.brand?.name || ""}
          </CustomTableCell>
          <CustomTableCell />
        </CustomTableRow>

        {/*<CustomTableRow>*/}
        {/*  <CustomTableCell component="th" scope="row">*/}
        {/*    <b>AdServer</b>*/}
        {/*  </CustomTableCell>*/}
        {/*  <CustomTableCell>{data.adServer}</CustomTableCell>*/}
        {/*  <CustomTableCell />*/}
        {/*</CustomTableRow>*/}

        <CustomTableRow>
          <CustomTableCell component="th" scope="row">
            <b>Language</b>
          </CustomTableCell>
          <CustomTableCell>{data.language?.name || ""}</CustomTableCell>
          <CustomTableCell>
            {/*<IconButton m={2} size="small" aria-label="View">*/}
            {/*  <EditIcon color="inherit" />*/}
            {/*</IconButton>*/}
          </CustomTableCell>
        </CustomTableRow>

        <CustomTableRow>
          <CustomTableCell component="th" scope="row">
            <b>Active</b>
          </CustomTableCell>
          <CustomTableCell>
            <CheckCircleIcon htmlColor="#00A854" />
          </CustomTableCell>
          <CustomTableCell />
        </CustomTableRow>

        <CustomTableRow>
          <CustomTableCell component="th" scope="row">
            <b>Countries</b>
          </CustomTableCell>
          <CustomTableCell>
            {regionsTemp?.toString().replaceAll(",", ", ")}
          </CustomTableCell>
          <CustomTableCell />
        </CustomTableRow>

        <CustomTableRow>
          <CustomTableCell component="th" scope="row">
            <b>Destination URL</b>
          </CustomTableCell>
          <CustomTableCell>
            <Link href={data.link} color="initial" target="_blank">
              {data.link}
            </Link>
          </CustomTableCell>
          <CustomTableCell />
        </CustomTableRow>
      </TableBody>
    </Table>
  ) : null;
}

export default DetailsTable;
