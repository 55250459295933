import React from "react";
import styled, {
  createGlobalStyle,
  ThemeProvider,
} from "styled-components/macro";

import {
  CssBaseline,
  MuiThemeProvider,
  Paper as MuiPaper,
} from "@material-ui/core";

import { THEMES } from "../constants";
import createTheme from "../theme";
import { spacing } from "@material-ui/system";
// import AppSearchBar from "../components/AppSearchBar";
import Footer from "../components/Footer";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Presentation = ({ children }) => {
  return (
    <MuiThemeProvider theme={createTheme(THEMES.DEFAULT)}>
      <ThemeProvider theme={createTheme(THEMES.DEFAULT)}>
        <Root>
          <CssBaseline />
          <GlobalStyle />
          <AppContent>
            {/*<AppSearchBar />*/}
            <MainContent>{children}</MainContent>
            <Footer />
          </AppContent>
        </Root>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default Presentation;
