import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { NavLink, useHistory, withRouter } from "react-router-dom";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";
import { useSelector } from "react-redux";

// import { spacing } from "@material-ui/system";

import {
  Badge,
  // Box as MuiBox,
  Chip,
  Grid,
  Avatar,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  // Button,
} from "@material-ui/core";

import {
  ExpandLess,
  ExpandMore,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";

// import { green } from "@material-ui/core/colors";

import { sidebarRoutes as routes } from "../routes/index";

import { ReactComponent as SpotonLogo } from "../vendor/spoton-logo.svg";
// import useAxios from "axios-hooks";
import { reportClean } from "../redux/actions/reportActions";
import axiosCache from "../utils/axiosCache";
import { SocialIcon } from "react-social-icons";
// import { makeStyles } from "@material-ui/core/styles";
// const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(SpotonLogo)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 145px;
  height: 45px;
`;

// const BrandChip = styled(Chip)`
//   background-color: ${green[700]};
//   border-radius: 5px;
//   color: ${(props) => props.theme.palette.common.white};
//   font-size: 55%;
//   height: 18px;
//   margin-left: 2px;
//   margin-top: -16px;
//   padding: 3px 0;
//
//   span {
//     padding-left: ${(props) => props.theme.spacing(1.375)}px;
//     padding-right: ${(props) => props.theme.spacing(1.375)}px;
//   }
// `;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryTitle = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
  cursor: pointer;
`;

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  isTitle,
  ...rest
}) => {
  return (
    <Category {...rest}>
      {icon}
      {isTitle ? (
        <CategoryTitle>{name}</CategoryTitle>
      ) : (
        <CategoryText>{name}</CategoryText>
      )}
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

const SidebarLink = ({ name, to, badge, icon }) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

const Sidebar = ({ classes, staticContext, location, ...rest }) => {
  const userDetails = useSelector((state) => state.authReducer.user);
  const refetchReport = useSelector((state) => state.reportReducer);
  const history = useHistory();
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());
  const [openBrand, setOpenBrands] = useState({ Others: true });

  const user = JSON.parse(localStorage.getItem("user"));
  const [{ data: userData }] = axiosCache({
    url: `/users/${user?.id}`,
  });

  const [{ data: savedReports }, refetch] = axiosCache({
    url: "/reports/",
    params: {
      user: user?.id,
    },
  });

  const data = JSON.stringify({
    jsonrpc: "2.0",
    id: 0,
    method: "socialinsider_api.get_profiles",
    params: {
      projectname: user?.client?.slug,
    },
  });

  const dataBrands = JSON.stringify({
    jsonrpc: "2.0",
    id: 0,
    method: "socialinsider_api.get_brands",
    params: {
      projectname: user?.client?.slug,
    },
  });

  const [{ data: profiles }] = axiosCache(
    {
      method: "POST",
      url: "/social-insider/",
      data: data,
    },
    { manual: !user?.client?.si_enabled }
  );

  const [{ data: brands }] = axiosCache(
    {
      method: "POST",
      url: "/social-insider/",
      data: dataBrands,
    },
    { manual: !user?.client?.si_enabled }
  );

  const brandGroup = brands?.result ? [...brands?.result] : [];
  if (profiles?.result)
    brandGroup.push({
      profiles: profiles?.result[0]?.profiles?.filter(
        (x) =>
          !brands?.result?.some((y) => y.profiles.some((z) => z.id === x.id))
      ),
      brandname: "Others",
    });

  useEffect(() => {
    if (brands?.result)
      setOpenBrands((prevState) =>
        Object.assign(
          {},
          prevState,
          ...brands?.result?.map((x) => ({
            [x?.brandname]: true,
          }))
        )
      );
  }, [brands]);

  useEffect(() => {
    if (refetchReport?.refetch) {
      refetch().then(() => reportClean());
    }
  }, [refetchReport, refetch]);

  function initOpenRoutes() {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    _routes = Object.assign(
      {},
      _routes,
      {
        "search-client": pathName.indexOf("/client") === 0,
      },
      {
        "search-product": pathName.indexOf("/clientProduct") === 0,
      }
    );

    return _routes;
  }

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  const toggleBrands = (index) => {
    // Toggle selected element
    setOpenBrands((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  function showRoutes() {
    let _routes = [];

    routes.forEach((route, index) => {
      if (userDetails?.groups?.indexOf("client") >= 0 && !route.client) return;
      if (!userDetails?.si_enabled && route.options?.social) return;
      _routes.push(route);
    });

    return _routes;
  }

  function goSettings() {
    history.push("/profile");
  }

  function getIcon(profileType) {
    const typeSplit = profileType.split("_");
    return typeSplit[0] ? (
      <SocialIcon
        network={typeSplit[0]}
        className={classes.socialIcon}
        fgColor={"#FFF"}
      />
    ) : null;
  }

  const productRoutes =
    savedReports?.results?.filter(
      (x) =>
        x.products?.length &&
        !(
          x.sectors?.length ||
          x.categories?.length ||
          x.brands?.length ||
          x.advertisers?.length
        )
    ) || [];
  const dashboardRoutes = savedReports?.results || [];

  return (
    <Drawer variant="permanent" {...rest} className={classes.drawer}>
      <Brand component={NavLink} to="/" button>
        <BrandIcon />
        {/*<Box ml={1}>*/}
        {/*  Spoton*/}
        {/*  /!*<BrandChip label="PRO" />*!/*/}
        {/*</Box>*/}
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {showRoutes().map((category, index) => (
              <React.Fragment key={index}>
                {category.header ? (
                  <SidebarSection>{category.header}</SidebarSection>
                ) : null}

                {category.children && category.icon ? (
                  <>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={category.id}
                      icon={category.icon}
                      button={true}
                      onClick={() => toggle(index)}
                    />

                    <Collapse
                      in={openRoutes[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {category.children.map((route, index) => (
                        <SidebarLink
                          key={index}
                          name={route.name}
                          to={route.path}
                          icon={route.icon}
                          badge={route.badge}
                        />
                      ))}
                      {category.id === "Profiles" && user?.client?.si_enabled
                        ? brandGroup?.map((brand) =>
                            brand?.profiles?.length ? (
                              <React.Fragment key={brand.brandname}>
                                <SidebarCategory
                                  key={brand.brandname}
                                  name={brand.brandname}
                                  isTitle
                                  style={{ paddingLeft: "52px" }}
                                  isOpen={!openBrand[brand.brandname]}
                                  isCollapsable={brand?.profiles?.length > 3}
                                  button
                                  onClick={() => toggleBrands(brand.brandname)}
                                />
                                <Collapse
                                  in={openBrand[brand.brandname]}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {brand?.profiles?.map((profile) => (
                                    <SidebarCategory
                                      key={profile.id}
                                      name={profile.name}
                                      // to={{
                                      //   pathname: `/social/profile/${profile.name}/${profile.id}/${profile.profile_type}`,
                                      // }}
                                      // component={NavLink}
                                      onClick={() =>
                                        history.push(
                                          `/social/profile/${profile.name}/${profile.id}/${profile.profile_type}`
                                        )
                                      }
                                      icon={getIcon(profile.profile_type)}
                                      button
                                      style={{ paddingLeft: "52px" }}
                                    />
                                  ))}
                                </Collapse>
                              </React.Fragment>
                            ) : null
                          )
                        : null}
                    </Collapse>
                  </>
                ) : category.icon ? (
                  <SidebarCategory
                    isCollapsable={false}
                    name={category.id}
                    to={category.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact
                    button
                    badge={category.badge}
                  />
                ) : null}
              </React.Fragment>
            ))}
            {dashboardRoutes?.length ? (
              <>
                <SidebarCategory
                  isOpen={!openRoutes["search-client"]}
                  isCollapsable={dashboardRoutes.length > 4}
                  name="Saved Searches"
                  // component={Typography}
                  button
                  isTitle
                  onClick={() => toggle("search-client")}
                />
                {dashboardRoutes.length > 4 ? (
                  <Collapse
                    in={openRoutes["search-client"]}
                    timeout="auto"
                    unmountOnExit
                  >
                    {dashboardRoutes.map((report, index) => (
                      <SidebarCategory
                        key={index}
                        name={report.name}
                        to={{
                          pathname: "/client",
                          state: { report: report },
                        }}
                        component={NavLink}
                        icon={<VisibilityIcon />}
                        exact
                        button
                      />
                    ))}
                  </Collapse>
                ) : (
                  dashboardRoutes.map((report, index) => (
                    <SidebarCategory
                      key={index}
                      name={report.name}
                      to={{
                        pathname: "/client",
                        state: { report: report },
                      }}
                      component={NavLink}
                      icon={<VisibilityIcon />}
                      exact
                      button
                    />
                  ))
                )}
              </>
            ) : null}

            {productRoutes.length ? (
              <>
                <SidebarCategory
                  isOpen={!openRoutes["search-client"]}
                  isCollapsable={productRoutes.length > 4}
                  name="Product's Saved Searches"
                  // component={Typography}
                  button={productRoutes.length > 4}
                  isTitle
                  onClick={() => toggle("search-product")}
                />
                {productRoutes.length > 4 ? (
                  <Collapse
                    in={openRoutes["search-product"]}
                    timeout="auto"
                    unmountOnExit
                  >
                    {productRoutes.map((report, index) => (
                      <SidebarCategory
                        key={index}
                        name={report.name}
                        to={{
                          pathname: "/productClient",
                          state: { report: report },
                        }}
                        component={NavLink}
                        icon={<VisibilityIcon />}
                        exact
                        button
                      />
                    ))}
                  </Collapse>
                ) : (
                  productRoutes.map((report, index) => (
                    <SidebarCategory
                      key={index}
                      name={report.name}
                      to={{
                        pathname: "/productClient",
                        state: { report: report },
                      }}
                      component={NavLink}
                      icon={<VisibilityIcon />}
                      exact
                      button
                    />
                  ))
                )}
              </>
            ) : null}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item>
            <SidebarFooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                alt={userDetails?.name}
                // src="/static/img/avatars/avatar-1.jpg"
                src={userData?.avatar}
                onClick={goSettings}
              />
            </SidebarFooterBadge>
          </Grid>
          <Grid item>
            <SidebarFooterText variant="body2">
              {userDetails?.name || ""}
            </SidebarFooterText>
            <SidebarFooterSubText variant="caption">
              {userData?.client?.name || ""}
            </SidebarFooterSubText>
          </Grid>
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
};

export default withRouter(Sidebar);
