import React, { useEffect, useState } from "react";
import {
  Avatar,
  Backdrop,
  Button,
  // Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  Add as AddIcon,
  CloseOutlined,
  CloudUpload as MuiCloudUpload,
  // Delete as DeleteIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import { setSnackNotification } from "../../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import TransferList from "./TransferList";
import useAxios from "axios-hooks";
import { makeStyles } from "@material-ui/core/styles";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  getComparator,
  stableSort,
} from "../../../utils/tablesUtils";
// import capitalize from "../../../utils/capitalize";
import NewUser from "./NewUser";

const TextFieldSpacing = styled(MuiTextField)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(TextFieldSpacing)`
  min-width: -webkit-fill-available;
`;

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
`;

const StatusChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
`;

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "username", alignment: "left", label: "Username" },
  { id: "status", alignment: "left", label: "Status" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "actions", alignment: "right", label: "Actions" },
];

const useStyles = makeStyles((theme) => ({
  transferList: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  searchBar: {
    display: "flex",
    marginBottom: "20px",
  },
  createButton: {
    margin: "auto 0px auto auto",
  },
  textField: {
    width: "300px",
  },
  textFieldName: {
    minWidth: "150px",
    width: "500px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function EnhancedTable({ client }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const dispatch = useDispatch();
  const [openEdit, setOpenEdit] = useState({ open: false, data: null });
  const [openNew, setOpenNew] = useState(false);

  const [filters, setFilters] = useState("");
  const [pagination, setPagination] = useState(1);
  const [{ data: rows, error }, refetch] = useAxios({
    url: "/users/",
    params: {
      page: pagination,
      page_size: rowsPerPage,
      search: filters,
      clients: client?.id || null,
    },
  });

  useEffect(() => {
    if (error) {
      dispatch(
        setSnackNotification("An error has occurred, please try again", "error")
      );
      console.log("error", error);
    }
  }, [error, dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.list.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];
  //
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPagination(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination(1);
  };

  // const isSelected = (id) => selected.indexOf(id) !== -1;

  function handleCloseNew(reload) {
    if (reload) refetch();
    setOpenNew(false);
  }

  function handleCloseEdit(reload) {
    if (reload) refetch();
    setOpenEdit({ open: false, data: null });
  }

  function handleChangeFilter(evt) {
    const { value } = evt.target;
    setFilters(value);
    setPagination(1);
  }

  return (
    <div>
      <Paper p={4}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          name="Clients"
          addNew={"Create User"}
        />
        <div className={classes.searchBar}>
          <TextFieldSpacing
            m={2}
            id="client"
            label="Type to Search"
            type="search"
            className={classes.textField}
            onChange={handleChangeFilter}
          />

          <Button
            variant="text"
            color="primary"
            className={classes.createButton}
            onClick={() => setOpenNew(true)}
            // style={{ float: "right" }}
          >
            <AddIcon />
            Create User
          </Button>
        </div>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.results?.length}
              headCells={headCells}
            />
            <TableBody>
              {rows &&
                stableSort(rows.results, getComparator(order, orderBy)).map(
                  (row, index) => {
                    // const isItemSelected = isSelected(row.id);
                    // const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        // selected={isItemSelected}
                      >
                        {/*<TableCell padding="checkbox">*/}
                        {/*  <Checkbox*/}
                        {/*    checked={isItemSelected}*/}
                        {/*    inputProps={{ "aria-labelledby": labelId }}*/}
                        {/*    onClick={(event) => handleClick(event, row.id)}*/}
                        {/*  />*/}
                        {/*</TableCell>*/}
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="left">{row.username}</TableCell>
                        <TableCell align="left">
                          <StatusChip
                            size="small"
                            label={row.is_active ? "Active" : "Inactive"}
                            rgbcolor={
                              row.is_active
                                ? "rgb(76,175,80)"
                                : "rgb(217,16,35)"
                            }
                          />
                        </TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="right">
                          {/*<IconButton aria-label="delete">*/}
                          {/*  <DeleteIcon />*/}
                          {/*</IconButton>*/}
                          <IconButton
                            aria-label="Edit"
                            onClick={() =>
                              setOpenEdit({ open: true, data: row })
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              {/*{emptyRows > 0 && (*/}
              {/*  <TableRow style={{ height: 53 * emptyRows }}>*/}
              {/*    <TableCell colSpan={7} />*/}
              {/*  </TableRow>*/}
              {/*)}*/}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50]}
          component="div"
          count={rows?.count || 0}
          rowsPerPage={rowsPerPage}
          page={pagination - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {openNew ? (
          <NewUser open={openNew} setClose={handleCloseNew} client={client} />
        ) : null}
        {openEdit.open ? (
          <NewUser
            open={openEdit.open}
            setClose={handleCloseEdit}
            editData={openEdit.data}
          />
        ) : null}
      </Paper>
    </div>
  );
}

function NewClient({ open, setClose, editData }) {
  const classes = useStyles();
  const [loadingData, setLoadingData] = useState(!!editData);
  const [data, setData] = useState({
    name: "",
    avatarSrc: "",
    avatarFile: "",
    countries: [],
    sectors: [],
    categories: [],
  });
  const [pagination] = useState({
    countries: 1,
    sectors: 1,
    categories: 1,
  });
  const [filters, setFilters] = useState({
    countries: "",
    sectors: "",
    categories: "",
  });
  const [{ data: countries, loading: loadingCountries }] = useAxios({
    url: "/countries/",
    params: {
      page: pagination.countries,
      page_size: 100,
      search: filters.countries,
    },
  });
  const [{ data: sectors, loading: loadingSectors }] = useAxios({
    url: "/sectors/",
    params: {
      page: pagination.sectors,
      page_size: 100,
      search: filters.sectors,
    },
  });

  const [categoriesData, setCategoriesData] = useState({
    results: [],
    page: 1,
    loading: true,
  });
  const [{ data: categories, loading: loadingCategories }] = useAxios({
    url: "/categories/",
    params: {
      page: categoriesData.page,
      page_size: 100,
      search: filters.categories,
    },
  });

  const [{ data: client, loading: loadingClient }] = useAxios(
    {
      url: `/clients/${editData?.id}`,
    },
    { manual: !editData }
  );

  const [, executeSave] = useAxios(
    {
      url: "/clients/",
      method: "POST",
    },
    { manual: true }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (categories?.results?.length) {
      setCategoriesData((prevState) => ({
        page: categories.next ? prevState.page + 1 : prevState.page,
        loading: !!categories.next,
        results: { ...prevState.results, [prevState.page]: categories.results },
      }));
    }
  }, [categories]);

  useEffect(() => {
    if (client) {
      setData(client);
      setLoadingData(false);
    }
  }, [client]);

  function handleChange(evt) {
    const { id, value } = evt.target;
    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  function onClose(reload) {
    setData({
      name: "",
      avatarSrc: "",
      avatarFile: "",
      countries: [],
      sectors: [],
      categories: [],
    });
    setClose(reload);
  }

  function handleAdd() {
    const { id } = editData || {};
    executeSave({
      method: id ? "PUT" : "POST",
      url: id ? `/clients/${id}` : "/clients/",
      data: {
        name: data.name,
        avatar: data.avatarSrc,
        countries: data.countries.map((x) => x.slug),
        categories: data.categories.map((x) => x.id),
        sectors: data.sectors.map((x) => x.id),
      },
    })
      .then(() => {
        dispatch(
          setSnackNotification("Operation successfully completed.", "success")
        );
        onClose(true);
      })
      .catch((error) => {
        dispatch(
          setSnackNotification(
            "An error has occurred, please try again",
            "error"
          )
        );
        console.log("error", error);
      });
  }

  function handleChangeAvatar(evt) {
    const { files } = evt.target;
    setData((prevState) => ({
      ...prevState,
      avatarFile: files[0],
    }));
    readImage(files[0]);
  }

  function readImage(file) {
    // Check if the file is an image.
    if (file.type && file.type.indexOf("image") === -1) {
      console.log("File is not an image.", file.type, file);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setData((prev) => ({
        ...prev,
        avatarSrc: event.target.result,
      }));
    });
    reader.readAsDataURL(file);
  }

  const isLoading =
    loadingCountries ||
    loadingClient ||
    loadingCategories ||
    loadingSectors ||
    loadingData ||
    categoriesData.loading;

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"lg"}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="form-dialog-title">
          {editData ? "Edit Client" : "Add New Client"}
          <CloseOutlined
            style={{ float: "right", cursor: "pointer" }}
            onClick={onClose}
          />
          <Typography variant="subtitle1">
            {editData
              ? ""
              : "Fill in the fields below to create and add a new user to the site"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <TextField
                m={2}
                required
                id="name"
                label="Name"
                value={data.name}
                className={classes.textFieldName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <CenteredContent>
                <BigAvatar alt={data.first_name} src={data.avatarSrc} />
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  // multiple
                  type="file"
                  onChange={handleChangeAvatar}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="contained" color="primary" component="span">
                    <CloudUpload mr={2} /> Upload
                  </Button>

                  <Typography variant="caption" display="block" gutterBottom>
                    For best results, use an image at least 128px by 128px in
                    .jpg format
                  </Typography>
                </label>
              </CenteredContent>
            </Grid>
            <Grid item xs={12}>
              <Divider my={5} mx={5} />
            </Grid>
            <Grid item xs={12} className={classes.transferList}>
              {!isLoading ? (
                <TransferList
                  type="countries"
                  filters={filters}
                  setFilters={setFilters}
                  available={countries?.results || []}
                  selected={data}
                  setSelected={setData}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} className={classes.transferList}>
              {!isLoading ? (
                <TransferList
                  type="sectors"
                  filters={filters}
                  setFilters={setFilters}
                  available={sectors?.results || []}
                  selected={data}
                  setSelected={setData}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} className={classes.transferList}>
              {!isLoading ? (
                <TransferList
                  type="categories"
                  filters={filters}
                  setFilters={setFilters}
                  available={
                    [].concat(...Object.values(categoriesData?.results)) || []
                  }
                  selected={data}
                  setSelected={setData}
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Divider my={5} mx={5} />
            </Grid>
            <Grid item xs={12}>
              {editData ? <EnhancedTable client={editData} /> : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            onClick={handleAdd}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!data.name}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewClient;
