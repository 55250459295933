import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import styled from "styled-components/macro";
import EnhancedTable from "../../Classify/components/EnhancedTable";
import { spacing } from "@material-ui/system";
import * as ClassifyService from "../../../services/classifyService";
import { makeStyles } from "@material-ui/core/styles";
import { CloseOutlined } from "@material-ui/icons";
import { setSnackNotification } from "../../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import useAxios from "axios-hooks";
import capitalize from "../../../utils/capitalize";

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  min-width: -webkit-fill-available;
`;

const useStyles = makeStyles(() => ({
  paper: {
    height: "80%",
  },
}));

function NewProduct({ open, setClose, editData }) {
  const [filters, setFilters] = React.useState({
    category: "",
    sector: "",
  });
  const [data, setData] = React.useState({
    name: "",
    category: null,
    sector: null,
  });
  const [pagination, setPagination] = useState({
    category: 1,
    sector: 1,
  });
  const [{ data: sectors, errorSectors }] = useAxios({
    url: "/sectors/",
    params: {
      page: pagination.sector,
      page_size: 15,
      search: filters.sector,
    },
  });
  const [{ data: categories, errorCategories }] = useAxios({
    url: "/categories/",
    params: {
      page: pagination.category,
      page_size: 15,
      search: filters.category,
      sector__in: data.sector,
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (editData)
      //TODO: Change when api permit search sectors by category
      setData({ name: editData.name });
  }, [editData]);

  useEffect(() => {
    if (errorSectors || errorCategories) {
      dispatch(
        setSnackNotification("An error has occurred, please try again", "error")
      );
      console.log("error", errorSectors || errorCategories);
    }
  }, [errorSectors, errorCategories, dispatch]);

  function onAccept() {
    const { id } = editData || {};
    const postData = { ...data, name: capitalize(data.name) };
    ClassifyService.postWithFormData(`/products/${id || ""}`, postData, !!id)
      .then(() => {
        dispatch(
          setSnackNotification("Operation successfully completed.", "success")
        );
        setClose(true);
      })
      .catch((error) => {
        dispatch(
          setSnackNotification(
            "An error has occurred, please try again",
            "error"
          )
        );
        console.log("error", error);
      });
  }

  function handleChange(evt) {
    const { id, value } = evt.target;
    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  function handleChangeFilter(evt) {
    const { id, value } = evt.target;
    setFilters((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setPagination((prevState) => ({ ...prevState, [id]: 1 }));
  }

  function handleClick(key, value) {
    setData((prevState) => ({
      ...prevState,
      [key]: prevState[key] === value ? null : value,
    }));
  }

  function handleClickSector(key, value) {
    setData((prevState) => ({
      ...prevState,
      sector: prevState.sector === value ? null : value,
      category: [],
    }));
    setPagination((prevState) => ({ ...prevState, category: 1 }));
  }

  function onClose(reload) {
    setData({
      name: "",
      category: null,
      sector: null,
    });
    setClose(reload);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"md"}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle id="form-dialog-title">
        {editData ? "Edit Product" : "Add Product"}
        <CloseOutlined
          style={{ float: "right", cursor: "pointer" }}
          onClick={onClose}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              m={2}
              required
              id="name"
              label="Name"
              value={data.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              m={2}
              id="sector"
              label="Type to Filter Industry"
              type="search"
              onChange={handleChangeFilter}
            />
            <EnhancedTable
              handleClick={handleClickSector}
              rows={sectors?.results}
              name="sector"
              selected={data.sector}
              page={pagination.sector}
              loadData={(p) =>
                setPagination((prevState) => ({ ...prevState, sector: p }))
              }
              showPagination
              count={sectors?.count}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              m={2}
              id="category"
              label="Type to Filter Category"
              type="search"
              onChange={handleChangeFilter}
            />
            <EnhancedTable
              rows={data.sector ? categories?.results : []}
              name="category"
              selected={data.category}
              handleClick={handleClick}
              page={data.sector ? pagination.category : 1}
              loadData={(p) =>
                setPagination((prevState) => ({ ...prevState, category: p }))
              }
              showPagination
              count={data.sector ? categories?.count : 0}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
          variant="contained"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          onClick={onAccept}
          color="primary"
          variant="contained"
          fullWidth
          disabled={!(data.name && data.sector && data.category)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewProduct;
