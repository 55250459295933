import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { CloseOutlined } from "@material-ui/icons";
import { setSnackNotification } from "../../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import capitalize from "../../../utils/capitalize";
import { Autocomplete } from "@material-ui/lab";
import useAxios from "axios-hooks";
import { makeStyles } from "@material-ui/core/styles";

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  min-width: -webkit-fill-available;
`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function NewCategory({ open, setClose, editData }) {
  const classes = useStyles();
  const [data, setData] = useState({
    name: "",
    sector: null,
  });
  const [filters, setFilters] = useState({
    sector: "",
  });
  const [loadingData, setLoadingData] = useState(!!editData);
  const dispatch = useDispatch();

  const [{ data: sectors, loading }] = useAxios({
    url: "/sectors/",
    params: {
      page_size: 15,
      search: filters.sector,
    },
  });

  const [{ data: category, loadingCat }] = useAxios(
    {
      url: `/categories/${editData?.id}`,
    },
    { manual: !editData }
  );

  const [, executeSave] = useAxios(
    {
      url: "/categories/",
      method: "POST",
    },
    { manual: true }
  );

  useEffect(() => {
    if (category) {
      setData(category);
      setLoadingData(false);
    }
  }, [category]);

  function handleChange(evt) {
    const { id, value } = evt.target;
    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  function onClose(reload) {
    setData({
      name: "",
      sector: null,
    });
    setClose(reload);
  }

  function handleAdd() {
    const { id } = editData || {};
    executeSave({
      method: id ? "PUT" : "POST",
      url: id ? `/categories/${id}` : "/categories/",
      data: {
        name: capitalize(data.name),
        sector: data?.sector?.id,
      },
    })
      .then(() => {
        dispatch(
          setSnackNotification("Operation successfully completed.", "success")
        );
        onClose(true);
      })
      .catch((error) => {
        dispatch(
          setSnackNotification(
            "An error has occurred, please try again",
            "error"
          )
        );
        console.log("error", error);
      });
  }

  function onInputAutoChange(evt, value) {
    if (evt) {
      const { id } = evt.target;
      setFilters((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
  }

  function handleChangeAuto(id, value) {
    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  const isLoading = loadingCat || loadingData || loading;

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={false}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="form-dialog-title">
          {editData ? "Edit Category" : "New Category"}
          <CloseOutlined
            style={{ float: "right", cursor: "pointer" }}
            onClick={onClose}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                m={2}
                required
                id="name"
                label="Name"
                value={data.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="sector"
                size="small"
                style={{ margin: "10px" }}
                options={
                  sectors?.results?.map((x) => ({ id: x.id, name: x.name })) ||
                  []
                }
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                value={data.sector}
                onInputChange={onInputAutoChange}
                onChange={(evt, val) => handleChangeAuto("sector", val)}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    // variant="outlined"
                    size="small"
                    style={{ height: "auto" }}
                    label="Industry"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            onClick={handleAdd}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!data.name}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewCategory;
