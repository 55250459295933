import axios from "../utils/axios";

export function getDataList(url) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function getDataListWithParams(url, data) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function deleteBanner(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/banners/?id__in=${id}`, {
        headers: {
          "X-BULK-OPERATION": "true",
        },
      })
      .then((response) => {
        if (response.status === 204) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function postWithFormData(url, data, isPut) {
  let bodyFormData = new FormData();
  Object.keys(data).forEach((key) => {
    data[key] && bodyFormData.append(key, data[key]);
  });
  return new Promise((resolve, reject) => {
    axios({
      method: isPut ? "put" : "post",
      url,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function postData(url, data, isPut) {
  return new Promise((resolve, reject) => {
    axios({
      method: isPut ? "put" : "post",
      url,
      data: data,
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function addClassify(url, data) {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, data, {
        headers: {
          "X-BULK-OPERATION": "true",
        },
      })
      .then((response) => {
        if (response.status === 204) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function createCampaign(url, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        reject(error);
      });
  });
}

export function getFiltersOptions(urls) {
  return Promise.all(
    urls.map(({ url, params }) => {
      return axios.get(url, params);
    })
  )
    .then((responses) => {
      responses.forEach((res) => {
        if (res.status !== 200) return Promise.reject(responses);
      });
      return Promise.resolve(responses.map((res) => res.data));
    })
    .catch((error) => {
      console.log("error", error);
      return Promise.reject(error);
    });
}
