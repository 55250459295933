import React from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow,
  TableFooter,
  TablePagination,
  IconButton,
  Collapse,
  Box,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  AddCircle,
  Edit as EditIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import TablePaginationActions from "../../../components/TablePaginationAction";

const TableCell = styled(MuiTableCell)`
  border: none;
  // height: 34px;
`;

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  hover: {
    cursor: "pointer",
  },
  rowName: {
    textTransform: "capitalize",
    display: "flex",
    flexDirection: "row",
  },
  subName: {
    fontSize: "10px",
    color: theme.palette.secondary.main,
    // alignSelf: "flex-end",
    // margin: "0 5px",
  },
}));

function EnhancedTable({
  rows,
  filters,
  name,
  selected,
  handleClick,
  showPagination,
  page,
  loadData,
  count,
  hideCheckbox,
  rowsPerPage,
  handleCheck,
  multiSelect,
  showCollapse,
  subKey,
  selectedSubKey,
  addButton,
  canEdit,
  editButton,
  subName,
  onHoverEnter,
  onHoverLeave,
  handleDoubleClick,
}) {
  const classes = useStyles1();
  const [open, setOpen] = React.useState(null);
  const [hover, setHover] = React.useState(null);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isSelectedSubRow = (name) =>
    selectedSubKey[subKey].indexOf(name) !== -1;
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    loadData(newPage + 1);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   // setRowsPerPage(parseInt(event.target.value, 10));
  //   // setPage(0);
  // };

  function handleCheckClick(name, row, event) {
    handleCheck && event.stopPropagation();
    handleCheck && handleCheck(name, row);
  }

  function handleAddButton(rowId, event) {
    event.stopPropagation();
    addButton(rowId);
  }

  function handleCheckSubRow(name, row, subRow, event) {
    handleCheck && event.stopPropagation();
    handleCheck && handleCheck(name, row, subRow);
  }

  function handleEditButton(rowId, event) {
    event.stopPropagation();
    editButton(rowId);
  }

  return (
    <Table size="small">
      <TableBody>
        {rows
          ?.filter((item) =>
            filters && filters[name]
              ? item.name.toLowerCase().indexOf(filters[name]) >= 0
              : true
          )
          .map((row, index) => {
            let isItemSelected;
            if (multiSelect) isItemSelected = isSelected(row.id);
            else isItemSelected = row.id === selected;
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <>
                <TableRow
                  hover
                  onClick={(event) =>
                    handleClick
                      ? handleClick(name, row.id)
                      : handleCheckClick
                      ? handleCheckClick(name, row, event)
                      : null
                  }
                  onDoubleClick={() =>
                    handleDoubleClick && handleDoubleClick(name, row.id)
                  }
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  className={classes.hover}
                  onMouseEnter={(evt) =>
                    onHoverEnter ? onHoverEnter(row.id, evt) : setHover(row.id)
                  }
                  onMouseLeave={() =>
                    onHoverLeave ? onHoverLeave() : setHover(null)
                  }
                >
                  {!hideCheckbox ? (
                    <TableCell
                      padding="checkbox"
                      style={
                        showCollapse && row[subKey]?.length
                          ? { width: "75px" }
                          : null
                      }
                    >
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(event) => handleCheckClick(name, row, event)}
                        inputProps={{ "aria-labelledby": labelId }}
                        color="primary"
                      />
                      {showCollapse && row[subKey]?.length ? (
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={(evt) => {
                            evt.stopPropagation();
                            setOpen(!open ? row.id : null);
                          }}
                        >
                          {open === row.id ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      ) : null}
                    </TableCell>
                  ) : null}
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.rowName}
                  >
                    <Typography>
                      {row.name?.toLowerCase()}
                      {subName && row[subName] ? (
                        <>
                          <span className={classes.subName}>
                            {" / "}
                            {row[subName].name}
                          </span>
                        </>
                      ) : null}
                    </Typography>
                    {showCollapse && hover === row.id ? (
                      <AddCircle
                        color="secondary"
                        cursor="pointer"
                        style={{ marginLeft: "auto" }}
                        fontSize="small"
                        onClick={(evt) => handleAddButton(row.id, evt)}
                      />
                    ) : null}
                    {canEdit && hover === row.id ? (
                      <EditIcon
                        color="secondary"
                        cursor="pointer"
                        style={{ marginLeft: "auto" }}
                        fontSize="small"
                        onClick={(evt) => handleEditButton(row.id, evt)}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
                {showCollapse && row[subKey].length ? (
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={open === row.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Table size="small" aria-label="purchases">
                            <TableBody>
                              {row[subKey]?.map((subRow) => {
                                let isItemSelected;
                                if (multiSelect)
                                  isItemSelected = isSelectedSubRow(subRow.id);
                                else
                                  isItemSelected = subRow.id === selectedSubKey;
                                return (
                                  <TableRow
                                    hover
                                    key={subRow.id}
                                    onClick={() =>
                                      handleClick &&
                                      handleClick(name, row.id, subRow.id)
                                    }
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                    className={classes.hover}
                                  >
                                    {!hideCheckbox ? (
                                      <TableCell padding="checkbox">
                                        <Checkbox
                                          checked={isItemSelected}
                                          onClick={(event) =>
                                            handleCheckSubRow(
                                              name,
                                              row,
                                              subRow,
                                              event
                                            )
                                          }
                                          inputProps={{
                                            "aria-labelledby": labelId,
                                          }}
                                          color="primary"
                                        />
                                      </TableCell>
                                    ) : null}
                                    <TableCell component="th" scope="row">
                                      {subRow.name}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                ) : null}
              </>
            );
          })}
      </TableBody>
      {showPagination ? (
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={3}
              count={count || 0}
              rowsPerPage={rowsPerPage || 15}
              page={page - 1}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      ) : null}
    </Table>
  );
}

export default EnhancedTable;
