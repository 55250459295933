import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid as MuiGrid,
  Paper as MuiPaper,
  FormControl as MuiFormControl,
  TextField,
  CircularProgress,
  Backdrop,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { CloseOutlined, ArrowBack } from "@material-ui/icons";
import { setSnackNotification } from "../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import ProgressiveImage from "../../components/ProgressiveImage";
import ProgressiveImageSize from "../../components/ProgressiveImageSize";
import Masonry from "../../components/Masonry";
import Resolution from "../../utils/resolution";
import useAxios from "axios-hooks";
import { makeStyles } from "@material-ui/core/styles";
import DetailsTable from "../Classify/components/DetailsTable";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const Grid = styled(MuiGrid)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const MuiPaperStyled = styled(MuiPaper)(spacing);

const Paper = styled(MuiPaperStyled)`
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaperArea = styled(Paper)`
  border: ${(props) =>
    props.left ? "5px solid #00a854" : "5px solid #FF0000"};
  box-sizing: border-box;
  border-radius: 7px;
`;

const useStyles = makeStyles((theme) => ({
  detailsRoot: {
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    gridGap: "10px",
  },
  arrow: {
    display: "flex",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function MergeCampaigns({ open, setClose, data }) {
  const classes = useStyles();
  const [selected, setSelected] = useState(null);
  const [columns, setColumns] = useState(Resolution());
  const [pagination, setPagination] = useState({
    campaigns: 1,
    advertisers: 1,
    brands: 1,
    products: 1,
  });
  const [filters, setFilters] = useState({
    brand: "",
    advertiser: "",
  });
  const [search, setSearch] = useState({
    brand: "",
    advertiser: "",
    campaign: "",
  });
  const dispatch = useDispatch();

  const [{ data: campaign, loading, error }] = useAxios(
    {
      url: `/campaigns/${data.id}`,
    },
    { manual: !data }
  );

  const [{ data: campaignsList, loadingCampaigns, errorCampaigns }] = useAxios(
    {
      url: `/campaigns/`,
      params: {
        page_size: 15,
        page: pagination.campaign,
        search: search.campaign,
        brand__in: filters.brand,
        advertiser__in: filters.advertiser,
      },
    },
    { manual: !data }
  );

  const [{ data: brands }] = useAxios({
    url: "/brands/",
    params: {
      page_size: 15,
      search: search.brand,
    },
  });
  const [{ data: advertisers }] = useAxios({
    url: "/advertisers/",
    params: {
      page_size: 15,
      search: search.advertiser,
    },
  });

  useEffect(() => {
    if (error || errorCampaigns) {
      dispatch(
        setSnackNotification("An error has occurred, please try again", "error")
      );
      console.log("error", error || errorCampaigns);
    }
  }, [error, errorCampaigns, dispatch]);

  function onResize() {
    setColumns(Resolution());
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  function handleChangeSearch(event) {
    const { value, name } = event.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleChange(value, name) {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value && value.id,
    }));
  }

  function handleChangeCheck(banner) {
    setSelected(banner);
  }

  return (
    <Dialog open={open} onClose={setClose} maxWidth={false}>
      <DialogTitle id="form-dialog-title">
        Compare
        <CloseOutlined
          style={{ float: "right", cursor: "pointer" }}
          onClick={setClose}
        />
      </DialogTitle>
      <DialogContent>
        <Backdrop
          className={classes.backdrop}
          open={loading || loadingCampaigns}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className={classes.detailsRoot}>
          <PaperArea left>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={4}>
                <ProgressiveImage
                  src={
                    campaign?.banner?.type?.indexOf("image") >= 0
                      ? campaign?.banner?.media
                      : campaign?.banner?.thumb
                  }
                  alt={campaign?.name}
                  type={campaign?.banner?.type}
                  customMaxHeight={"400px"}
                  // customWidth={"143px"}
                  objectFit={"contain"}
                  margin="auto"
                />
              </Grid>
              <Grid item xs={8}>
                <DetailsTable
                  data={campaign?.banner}
                  // regions={display.list?.map((d) => d.region)}
                  // desktop={display.list?.some(
                  //   (d) => d.browser_type.slug === "desktop"
                  // )}
                  // mobile={display.list?.some(
                  //   (d) => d.browser_type.slug === "mobile"
                  // )}
                />
              </Grid>
            </Grid>
          </PaperArea>
          <div className={classes.arrow}>
            <ArrowBack fontSize="large" />
          </div>
          <PaperArea right>
            {selected ? (
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <ProgressiveImage
                    src={
                      selected?.banner?.type?.indexOf("image") >= 0
                        ? selected?.banner?.media
                        : selected?.banner?.thumb
                    }
                    alt={selected?.name}
                    type={selected?.banner?.type}
                    customMaxHeight={"400px"}
                    // customWidth={"143px"}
                    objectFit={"contain"}
                    margin="auto"
                  />
                </Grid>
                <Grid item xs={8}>
                  <DetailsTable
                    data={selected?.banner}
                    // regions={display.list?.map((d) => d.region)}
                    // desktop={display.list?.some(
                    //   (d) => d.browser_type.slug === "desktop"
                    // )}
                    // mobile={display.list?.some(
                    //   (d) => d.browser_type.slug === "mobile"
                    // )}
                  />
                </Grid>
              </Grid>
            ) : (
              <Typography variant="h6" gutterBottom>
                Select a Campaign
              </Typography>
            )}
          </PaperArea>
        </div>
        <Paper mt={2}>
          <Grid container spacing={3} mt={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" m={2} size="small">
                <TextField
                  id="campaign-search"
                  name="campaign"
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={search.campaign}
                  onChange={handleChangeSearch}
                />
              </FormControl>
              <FormControl variant="outlined" m={2} size="small">
                <Autocomplete
                  id="brand"
                  name="brand"
                  size="small"
                  options={brands?.results || []}
                  style={{ width: 200 }}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name}
                  onChange={(evt, value) => handleChange(value, "brand")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="brand"
                      label="Brand"
                      variant="outlined"
                      onChange={handleChangeSearch}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant="outlined" m={2} size="small">
                <Autocomplete
                  id="advertiser"
                  name="advertiser"
                  size="small"
                  options={advertisers?.results || []}
                  style={{ width: 200 }}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name}
                  onChange={(evt, value) => handleChange(value, "advertiser")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="advertiser"
                      label="Advertiser"
                      variant="outlined"
                      onChange={handleChangeSearch}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Masonry
                columns={[...Array(columns).keys()]}
                page={pagination.campaigns}
                count={campaignsList?.count}
                loadData={(p) =>
                  setPagination((prevState) => ({ ...prevState, campaigns: p }))
                }
                withPagination
                // rowsPerPage={rowsPerPage}
                // setRowsPerPage={setRowsPerPage}
              >
                {campaignsList?.results?.map((campaign) => {
                  return (
                    <ProgressiveImageSize
                      src={
                        campaign?.banner?.type?.indexOf("image") >= 0
                          ? campaign?.banner?.media
                          : campaign?.banner?.thumb
                      }
                      alt={`merge-${campaign?.banner?.id}`}
                      size={campaign?.banner?.size}
                      selected={selected?.id === campaign?.id}
                      type={campaign?.banner?.type}
                      onClick={() => handleChangeCheck(campaign)}
                      key={`merge-${campaign?.banner?.id}`}
                    />
                  );
                })}
              </Masonry>
              {/*<CompareTable*/}
              {/*  banners={bannersToCompare}*/}
              {/*  selected={selected}*/}
              {/*  setSelected={setSelected}*/}
              {/*/>*/}
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
    </Dialog>
  );
}

export default MergeCampaigns;
