import * as types from "../../constants";

export function setSnackNotification(msg, severity) {
  return {
    type: types.SNACK_NOTIFICATION_SET,
    open: true,
    msg,
    severity,
  };
}

export function clearSnackNotification() {
  return {
    type: types.SNACK_NOTIFICATION_CLEAR,
    open: false,
    msg: "",
    severity: "",
  };
}
