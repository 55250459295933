import React from "react";
import styled from "styled-components/macro";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  List,
  ListItem,
  ListItemIcon,
  Link as MuiLink,
  Tooltip,
} from "@material-ui/core";

import {
  Smartphone as SmartphoneIcon,
  DesktopWindows as DesktopWindowsIcon,
} from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";

const CustomTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Link = styled(MuiLink)`
  overflow-wrap: anywhere;
`;

function DetailsTable({ data, regions, desktop, mobile }) {
  const regionsTemp = regions
    ?.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [])
    .map((r) => r.name);
  return (
    <Table size="small">
      <TableBody>
        <CustomTableRow>
          <CustomTableCell component="th" scope="row" width="30%">
            <b>First Seen</b>
          </CustomTableCell>
          <CustomTableCell width="70%">{data?.date_start}</CustomTableCell>
        </CustomTableRow>

        <CustomTableRow>
          <CustomTableCell component="th" scope="row" width="30%">
            <b>Size</b>
          </CustomTableCell>
          <CustomTableCell width="70%">{data?.size}</CustomTableCell>
        </CustomTableRow>

        <CustomTableRow>
          <CustomTableCell component="th" scope="row" width="30%">
            <b>AdServers</b>
          </CustomTableCell>
          <CustomTableCell width="70%">{data?.adServers}</CustomTableCell>
        </CustomTableRow>

        <CustomTableRow>
          <CustomTableCell component="th" scope="row" width="30%">
            <b>Country</b>
          </CustomTableCell>
          <CustomTableCell width="70%">
            {regionsTemp?.toString().replaceAll(",", ", ")}
          </CustomTableCell>
        </CustomTableRow>

        <CustomTableRow>
          <CustomTableCell component="th" scope="row" width="30%">
            <b>Destination URL</b>
          </CustomTableCell>
          <CustomTableCell width="70%">
            <Link href={data?.link} color="initial" target="_blank">
              {data?.link}
            </Link>
          </CustomTableCell>
        </CustomTableRow>

        <CustomTableRow>
          <CustomTableCell component="th" scope="row" width="30%">
            <b>Type</b>
          </CustomTableCell>
          <CustomTableCell width="70%">
            <List dense>
              <ListItem>
                <ListItemIcon>
                  {mobile ? (
                    <Tooltip title="Mobile">
                      <SmartphoneIcon style={{ marginRight: "5px" }} />
                    </Tooltip>
                  ) : null}
                  {desktop ? (
                    <Tooltip title="Desktop">
                      <DesktopWindowsIcon />
                    </Tooltip>
                  ) : null}
                </ListItemIcon>
              </ListItem>
            </List>
          </CustomTableCell>
        </CustomTableRow>
      </TableBody>
    </Table>
  );
}

export default DetailsTable;
