import React, { useEffect, useState } from "react";
import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField as MuiTextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  CloseOutlined,
  CloudUpload as MuiCloudUpload,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import { setSnackNotification } from "../../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import useAxios from "axios-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  min-width: -webkit-fill-available;
`;

const CloudUpload = styled(MuiCloudUpload)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)}px;
`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function NewUser({ open, setClose, editData, client }) {
  const classes = useStyles();
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    password: "",
    avatarSrc: "",
    avatarFile: "",
    client: client || null,
  });
  const [showPass, setShowPass] = useState(false);
  const [clientFilter, setClientFilter] = useState("");
  const dispatch = useDispatch();

  const [{ data: userData, loading }] = useAxios(
    {
      url: `/users/${editData?.id}`,
    },
    { manual: !editData?.id }
  );

  const [{ data: clients }] = useAxios({
    url: `/clients/`,
    params: {
      search: clientFilter,
    },
  });

  const [, executeSave] = useAxios(
    {
      url: "/users/",
      method: "POST",
    },
    { manual: true }
  );

  useEffect(() => {
    if (userData && !loading)
      setData({
        ...userData,
      });
  }, [userData, loading]);

  function handleChange(evt) {
    const { id, value } = evt.target;
    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  function onClose(reload) {
    setData({
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      password: "",
      avatarSrc: "",
      avatarFile: "",
      client: null,
    });
    setClose(reload);
  }

  function handleAdd() {
    const { id } = editData || {};
    executeSave({
      method: id ? "PATCH" : "POST",
      url: id ? `/users/${id}` : "/users/",
      data: {
        username: data.username,
        password: data.password,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        avatar: data.avatar_src,
        client_id: data.client?.id || undefined,
      },
    })
      .then(() => {
        dispatch(
          setSnackNotification("Operation successfully completed.", "success")
        );
        onClose(true);
      })
      .catch((error) => {
        dispatch(
          setSnackNotification(
            "An error has occurred, please try again",
            "error"
          )
        );
        console.log("error", error);
      });
  }

  function handleChangeAvatar(evt) {
    const { files } = evt.target;
    setData((prevState) => ({
      ...prevState,
      avatarFile: files[0],
    }));
    readImage(files[0]);
  }

  function readImage(file) {
    // Check if the file is an image.
    if (file.type && file.type.indexOf("image") === -1) {
      console.log("File is not an image.", file.type, file);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      setData((prev) => ({
        ...prev,
        avatarSrc: event.target.result,
      }));
    });
    reader.readAsDataURL(file);
  }

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function onInputAutoChange(evt, value) {
    setClientFilter(value);
  }

  function handleChangeAuto(id, value) {
    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"lg"}>
        <DialogTitle id="form-dialog-title">
          {editData ? "Edit User" : "Add New User"}
          <CloseOutlined
            style={{ float: "right", cursor: "pointer" }}
            onClick={onClose}
          />
          <Typography variant="subtitle1">
            {editData
              ? ""
              : "Fill in the fields below to create and add a new user to the site"}
          </Typography>
        </DialogTitle>
        <Divider my={5} />
        <DialogContent>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <TextField
                m={2}
                required
                id="username"
                label="Username"
                variant="outlined"
                value={data.username}
                onChange={handleChange}
              />
              <TextField
                m={2}
                required
                id="password"
                label="Password"
                variant="outlined"
                type={showPass ? "text" : "password"}
                value={data.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPass ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <CenteredContent>
                <BigAvatar alt={data.first_name} src={data.avatarSrc} />
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  // multiple
                  type="file"
                  onChange={handleChangeAvatar}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="contained" color="primary" component="span">
                    <CloudUpload mr={2} /> Upload
                  </Button>

                  <Typography variant="caption" display="block" gutterBottom>
                    For best results, use an image at least 128px by 128px in
                    .jpg format
                  </Typography>
                </label>
              </CenteredContent>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Personal Info
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                m={2}
                required
                id="first_name"
                label="First name"
                variant="outlined"
                value={data.first_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                m={2}
                required
                id="last_name"
                label="Last name"
                variant="outlined"
                value={data.last_name}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                m={2}
                required
                id="email"
                label="Email"
                variant="outlined"
                type="email"
                value={data.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Client
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Autocomplete
                id="client"
                // size="small"
                style={{ width: 200, margin: "10px" }}
                options={clients?.results || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                value={data.client}
                onInputChange={onInputAutoChange}
                onChange={(evt, val) => handleChangeAuto("client", val)}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    variant="outlined"
                    // size="small"
                    // style={{ height: "auto" }}
                    label="Client Id"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
            // fullWidth
          >
            Cancel
          </Button>
          <Button
            onClick={handleAdd}
            color="primary"
            variant="contained"
            // fullWidth
            disabled={!data.username}
          >
            {editData ? "Edit" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewUser;
