import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Backdrop,
  CircularProgress,
  TextField as MuiTextField,
} from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { setSnackNotification } from "../../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import useAxios from "axios-hooks";
import EnhancedTable from "../../Classify/components/EnhancedTable";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  width: 100%;
`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function ModalSelect({ handleOk, open, setClose, url, type }) {
  const classes = useStyles();
  const [pagination, setPagination] = useState(1);
  const [filter, setFilter] = useState();
  const [selected, setSelected] = useState();
  const [{ data: list, loading, error }] = useAxios({
    url: url,
    params: {
      page: pagination,
      page_size: 15,
      search: filter,
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(
        setSnackNotification("An error has occurred, please try again", "error")
      );
      console.log("error", error);
    }
  }, [error, dispatch]);

  function onAccept() {
    const obj = list?.results.find((x) => x.id === selected);
    handleOk(type, obj);
  }

  function handleClick(key, value) {
    setSelected(selected === value ? null : value);
  }

  function handleDoubleClick(key, value) {
    const obj = list?.results.find((x) => x.id === value);
    handleOk(type, obj);
  }

  function handleChange(evt) {
    const { value } = evt.target;
    setFilter(value);
  }

  return (
    <>
      <Dialog open={open} onClose={setClose} maxWidth={false}>
        <DialogTitle id="form-dialog-title">
          Select
          <CloseOutlined
            style={{ float: "right", cursor: "pointer" }}
            onClick={setClose}
          />
        </DialogTitle>
        <DialogContent>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                m={2}
                id="filter"
                label="Type to Filter"
                type="search"
                value={filter}
                onChange={handleChange}
              />
              <EnhancedTable
                rows={list?.results}
                name="data"
                selected={selected}
                handleClick={handleClick}
                handleDoubleClick={handleDoubleClick}
                page={pagination}
                loadData={(p) => setPagination(p)}
                showPagination
                count={list?.count}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={setClose}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            onClick={onAccept}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!selected}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ModalSelect;
