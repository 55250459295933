function getProjectVariant(size) {
  if (size) {
    let ratios = [0.2, 1.2, 8.0];
    const dim = size?.split("x");
    let currRatio = parseInt(dim[0]) / parseInt(dim[1]);

    let closest = ratios.reduce(function (prev, curr) {
      return Math.abs(curr - currRatio) < Math.abs(prev - currRatio)
        ? curr
        : prev;
    });

    if (closest === 0.2) {
      return "large";
    }
    if (closest === 1.2) {
      return "medium";
    }
    if (closest === 8.0) {
      return "small";
    }
  } else {
    return "medium";
  }
}

export default getProjectVariant;
