import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { CloseOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import DisplayDetailsTable from "../common/DisplayDetailsTable";
import useAxios from "axios-hooks";
import DetailsTable from "../common/DetailsTable";
import ProgressiveImage from "../../components/ProgressiveImage";

const Grid = styled(MuiGrid)(spacing);

const MuiPaperStyled = styled(MuiPaper)(spacing);

const Paper = styled(MuiPaperStyled)`
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: "capitalize",
  },
  paper: {
    height: "90%",
  },
  paperFlex: {
    display: "flex",
    flexDirection: "column",
  },
  tabs: {
    flexGrow: 1,
    width: "100%",
    flexDirection: "column",
  },
  tabPanel: {
    width: "100%",
    // height: "500px",
    padding: "50px",
  },
  dateField: {
    padding: theme.spacing(2.5),
    margin: "0 10px 0 auto",
  },
}));

function AdsDetails({ open, setOpen, banner, campaign }) {
  const classes = useStyles();
  const [pagination, setPagination] = useState(1);

  const [{ data: displays }] = useAxios({
    url: "/displays/",
    params: {
      banner: banner?.id,
      page_size: 15,
      page: pagination,
    },
  });

  const [{ data: bannerDetails }] = useAxios({
    url: `/banners/${banner?.id}`,
  });

  const [{ data: campaignData }] = useAxios(
    { url: `/campaigns/${campaign?.id}` },
    { manual: !campaign?.id }
  );

  const [{ data: countries }] = useAxios({
    url: `/countries/`,
  });

  return (
    <Dialog
      open={open}
      onClose={() => setOpen({ open: false, banner: null })}
      fullWidth={true}
      maxWidth={"xl"}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle className={classes.title}>
        Banner Details
        <CloseOutlined
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => setOpen({ open: false, banner: null })}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Paper>
              <ProgressiveImage
                src={bannerDetails?.media}
                alt={bannerDetails?.id}
                type={bannerDetails?.type?.split("/")[0]}
              />
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper className={classes.paperFlex}>
              <DetailsTable
                data={bannerDetails}
                campaign={campaignData}
                // regions={displays?.results?.map((d) => d.region)}
                regions={countries?.results?.filter((c) =>
                  bannerDetails?.countries?.some((b) => b === c.id)
                )}
              />
              <Typography component="div" className={classes.dateField}>
                <Box fontWeight="fontWeightBold" m={1}>
                  {bannerDetails && `First Seen: ${bannerDetails.date_start}`}
                </Box>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid xs={12} item>
            <Paper className={classes.tabs}>
              <DisplayDetailsTable
                rows={displays?.results}
                page={pagination}
                loadData={(p) => setPagination(p)}
                showPagination
                count={displays?.count}
              />
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AdsDetails;
