import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Typography as MuiTypography,
  Tabs,
  Tab,
  AppBar,
  Box,
  Button as MuiButton,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  CloseOutlined,
  CloudDownload as CloudDownloadIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { CampaignTable } from "./DetailsTable";
import { Pie, Bar } from "react-chartjs-2";
import ProgressiveImageSize from "../../components/ProgressiveImageSize";
import Masonry from "../../components/Masonry";
import Resolution from "../../utils/resolution";
import useAxios from "axios-hooks";
import AdsDetails from "./AdsDetails";
import ProgressiveImage from "../../components/ProgressiveImage";
import randomColor from "randomcolor";
import formatter from "../../utils/formatter";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import CircularProgressWithLabel from "../../components/CircularProcessWithLabel";
import { setSnackNotification } from "../../redux/actions/snackAction";
import { useDispatch } from "react-redux";

const Grid = styled(MuiGrid)(spacing);

const Typography = styled(MuiTypography)(spacing);

const MuiPaperStyled = styled(MuiPaper)(spacing);

const Paper = styled(MuiPaperStyled)`
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled(MuiButton)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: "capitalize",
  },
  paper: {
    height: "90%",
  },
  paperFlex: {
    display: "flex",
    flexDirection: "column",
  },
  tabs: {
    flexGrow: 1,
    width: "100%",
    flexDirection: "column",
    position: "relative",
  },
  tabPanel: {
    width: "100%",
    minHeight: "600px",
    padding: "50px",
  },
  dateField: {
    padding: theme.spacing(2.5),
    margin: "0 10px 0 auto",
  },
  buttonDownload: {
    position: "absolute",
    top: "5px",
    right: "10px",
    display: "flex",
  },
  // tabBanners: {
  //   height: "100%",
  //   flexDirection: "column",
  //   overflowY: "auto",
  // },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const colors = ["#EA7126", "#69BB92", "#9492EE", "#1E88E5", "#DBB54A"];

function CampaignDetails({
  open,
  setOpen,
  campaign: campaignData,
  editCountry,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(Resolution());
  const [downloading, setDownloading] = useState({
    active: false,
    progress: 0,
  });
  const [openDetails, setOpenDetails] = useState({
    open: false,
    banner: null,
  });
  const [pagination, setPagination] = useState(1);

  const [{ data: banners }] = useAxios({
    url: "/banners/",
    params: {
      campaign: campaignData?.id,
      page_size: 100,
      page: pagination,
    },
  });

  const [{ data: campaign }] = useAxios({
    url: `/campaigns/${campaignData.id}`,
  });

  const spendTotal = campaign?.stats?.cost;
  const viewsTotal = campaign?.stats?.view;

  function onResize() {
    setColumns(Resolution());
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const [tabValue, setTabValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  function adFormatData() {
    return campaign?.stats?.banners?.types?.reduce(
      (acc, current) => {
        if (current.name.split("/")[0] === "image") acc[0] += current.count;
        if (current.name.split("/")[0] === "video") acc[1] += current.count;
        return acc;
      },
      [0, 0]
    );
  }

  const data = {
    labels: ["Image", "Video"],
    datasets: [
      {
        label: "AdFormats",
        data: adFormatData(),
        backgroundColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const impressionsStats = campaign?.stats?.websites?.map((x, key) => ({
    ...x,
    color: key < 5 ? colors[key] : randomColor(),
  }));

  const data2 = {
    // labels: impressionsStats?.map((x) => x.name),
    labels: [""],
    datasets: impressionsStats?.map((x) => ({
      label: x.name,
      data: [x.view],
      backgroundColor: x.color,
      borderColor: x.color,
      fill: false,
      barPercentage: 0.5,
      // borderWidth: 1,
    })),
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  function handleDownloads() {
    try {
      setDownloading({ active: true, progress: 0 });
      const zip = new JSZip();
      let count = 0;
      const zipFilename = `${campaign?.name} ${campaign?.date_start}.zip`;
      const urls = banners?.results?.map((x) => x.media) || [];
      const total = urls.length;

      urls.forEach(function (url, index) {
        const parts = url.split("/");
        const filename = parts[parts.length - 1];
        // loading a file and add it in a zip file
        JSZipUtils.getBinaryContent(url, async function (err, data) {
          if (err) {
            throw err; // or handle the error
          }
          zip.file(filename, data, { binary: true });
          count++;
          setDownloading({
            active: true,
            progress: (count * 100) / total,
          });
          if (count === total) {
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, zipFilename);
            });
            setDownloading({ active: false, progress: 0 });
          }
        });
      });
    } catch (e) {
      dispatch(
        setSnackNotification("An error has occurred, please try again", "error")
      );
      console.log("error", e);
      setDownloading({ active: false, progress: 0 });
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen({ open: false, campaign: null })}
        fullWidth={true}
        maxWidth={"xl"}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle className={classes.title}>
          {campaign?.name}
          <CloseOutlined
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => setOpen({ open: false, campaign: null })}
          />
          {/*<Typography variant="subtitle1">*/}
          {/*  <b>AdServer:</b> GOOGLE*/}
          {/*</Typography>*/}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Paper>
                <ProgressiveImage
                  src={campaign?.banner?.media}
                  alt={campaign?.name}
                  type={campaign?.banner?.type?.split("/")[0]}
                />
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper className={classes.paperFlex}>
                <CampaignTable
                  data={campaign}
                  // regions={banners?.results?.map((d) => d.region)}
                  url={banners?.results[0]?.link}
                  editCountry={editCountry}
                />
                <Typography component="div" className={classes.dateField}>
                  <Box fontWeight="fontWeightBold" m={1}>
                    {campaign?.date_start
                      ? `Start Date: ${campaign.date_start}`
                      : ""}
                  </Box>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={4}>
              <Paper className={classes.paperFlex}>
                <Typography variant="h3">
                  {viewsTotal?.toLocaleString("en-US")}
                </Typography>
                <Typography variant="h4">Est. Impressions</Typography>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <Pie
                  data={data}
                  height={150}
                  options={{
                    plugins: {
                      title: { display: true, text: "AdFormats" },
                      legend: {
                        position: "left",
                        labels: {
                          generateLabels: function (chart) {
                            const items = [];
                            const ds = chart.data.datasets[0];
                            const sum = ds.data.reduce(function add(a, b) {
                              return a + b;
                            }, 0);
                            for (let i = 0; i < ds.data.length; i++) {
                              const percent = Math.round(
                                (100 * ds.data[i]) / sum
                              );
                              items.push({
                                datasetIndex: i,
                                text: `${chart.data.labels[i]}: (${
                                  percent || 0
                                }%)`,
                                fontColor: ds.backgroundColor[i],
                                fillStyle: ds.backgroundColor[i],
                                lineWidth: 0,
                              });
                            }
                            return items;
                          },
                        },
                        // : undefined,
                      },
                    },
                    maintainAspectRatio: false,
                  }}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.paperFlex}>
                <Typography variant="h3">
                  {spendTotal && formatter.format(spendTotal)}
                </Typography>
                <Typography variant="h4">Est. Spent</Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid xs={12} item>
              <Paper className={classes.tabs}>
                <AppBar position="static" color="transparent">
                  <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeTab}
                    // aria-label="disabled tabs example"
                  >
                    <Tab color="default" label="Ads" {...a11yProps(0)} />
                    <Tab label="Est. Impressions by Site" {...a11yProps(1)} />
                    {banners?.count > 0 && tabValue === 0 ? (
                      <div className={classes.buttonDownload}>
                        {downloading.active ? (
                          <CircularProgressWithLabel
                            value={downloading.progress}
                          />
                        ) : null}
                        <Button
                          // size="small"
                          mr={2}
                          onClick={handleDownloads}
                          disabled={downloading.active}
                        >
                          <Typography variant="body2" mr={2}>
                            Download all Ads
                          </Typography>
                          <CloudDownloadIcon />
                        </Button>
                      </div>
                    ) : null}
                  </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index={0}>
                  <Masonry
                    columns={[...Array(columns).keys()]}
                    page={pagination}
                    rowsPerPage={100}
                    count={banners?.count}
                    loadData={(p) => setPagination(p)}
                    withPagination
                  >
                    {banners?.results.map((banner) => {
                      return (
                        <ProgressiveImageSize
                          key={banner.id}
                          src={
                            banner.type.indexOf("video") !== -1
                              ? banner.thumb
                              : banner.media
                          }
                          alt={`camp-details-${banner.id}`}
                          size={banner.size}
                          type={banner.type}
                          onClick={() =>
                            setOpenDetails({ open: true, banner: banner })
                          }
                        />
                      );
                    })}
                  </Masonry>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Bar data={data2} width={"100%"} options={options} />
                </TabPanel>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {openDetails.open ? (
        <AdsDetails
          open={openDetails.open}
          setOpen={setOpenDetails}
          banner={openDetails.banner}
          campaign={campaign}
        />
      ) : null}
    </>
  );
}

export default CampaignDetails;
