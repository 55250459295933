import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

// For routes that can only be accessed by authenticated users
function SIGuard({ children }) {
  const auth = useSelector((state) => state.authReducer);

  if (!auth.user) {
    return <Redirect to="/auth/sign-in" />;
  }
  if (!auth.user?.si_enabled) return <Redirect to="/404" />;
  return children;
}

export default SIGuard;
