import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

// For routes that can only be accessed by authenticated users
function AdminGuard({ children }) {
  const auth = useSelector((state) => state.authReducer);

  if (!auth.user) {
    return <Redirect to="/auth/sign-in" />;
  }
  if (!auth?.user?.groups?.some((x) => ["admin", "mods"].indexOf(x) >= 0))
    return <Redirect to="/client" />;
  return children;
}

export default AdminGuard;
