import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import Helmet from "react-helmet";

import {
  Button as MuiButton,
  // Checkbox,
  Divider as MuiDivider,
  Grid as MuiGrid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField as MuiTextField,
  Typography,
  withWidth,
  Collapse,
  Box,
  Tooltip,
  Switch,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import { setSnackNotification } from "../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import { isWidthUp } from "@material-ui/core/withWidth";
import {
  EnhancedTableHead /*EnhancedTableToolbar*/,
} from "../../utils/tablesUtils";
import useAxios from "axios-hooks";
import NewCampaign from "./components/NewCampaign";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import Masonry from "../../components/Masonry";
import Resolution from "../../utils/resolution";
import ProgressiveImage from "../../components/ProgressiveImage";
import capitalize from "../../utils/capitalize";
import BannerPopover from "../common/BannerPopover";
import MergeCampaigns from "../common/MergeCampaigns";
import ConfirmDialog from "../../components/ConfirmDialog";
import { makeStyles } from "@material-ui/core/styles";
import CampaignDetails from "../Client/CampaignDetails";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

const TextFieldSpacing = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);
const Autocomplete = styled(MuiAutocomplete)(spacing);

const Grid = styled(MuiGrid)(spacing);

const TextField = styled(TextFieldSpacing)`
  width: 300px;
  margin-bottom: 20px;
`;

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "collapse", alignment: "left", label: "" },
  { id: "id", alignment: "left", label: "ID" },
  { id: "image", alignment: "left", label: "Image" },
  { id: "name", alignment: "left", label: "Name" },
  { id: "brand", alignment: "left", label: "Brand" },
  { id: "category", alignment: "left", label: "Category" },
  { id: "advertiser", alignment: "left", label: "Advertiser" },
  { id: "actions", alignment: "center", label: "Actions", width: "150px" },
];

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  actions: {
    // display: "flex",
  },
}));

function Row({
  row,
  openCollapse,
  setOpenCollapse,
  setOpenEdit,
  setConfirmOpen,
  banners,
  refetchBanners,
  setOpenDetails,
}) {
  const classes = useStyles();
  const [columns, setColumns] = useState(Resolution());

  function onResize() {
    setColumns(Resolution());
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <>
      <TableRow
        hover
        // role="checkbox"
        // aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        className={classes.tableRow}
        // selected={isItemSelected}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              setOpenCollapse((prev) => (prev === row.id ? null : row.id))
            }
          >
            {openCollapse === row.id ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell align="left">{row.id}</TableCell>
        <TableCell align="left">
          <ProgressiveImage
            src={
              row.banner?.type?.indexOf("image") >= 0
                ? row?.banner?.media
                : row?.banner?.thumb
            }
            alt={row.name}
            type={row?.banner?.type}
            customMaxHeight={"300px"}
            customWidth={"200px"}
            objectFit={"contain"}
          />
        </TableCell>
        <TableCell align="left">{capitalize(row.name)}</TableCell>
        <TableCell align="left">{row.brand?.name}</TableCell>
        <TableCell align="left">{row.category?.name}</TableCell>
        <TableCell align="left">{row.advertiser?.name}</TableCell>
        <TableCell align="center" width={"185px"} className={classes.actions}>
          <Tooltip title="Details">
            <IconButton
              aria-label="Details"
              onClick={() => setOpenDetails({ open: true, campaign: row })}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              aria-label="Edit"
              onClick={() => setOpenEdit({ open: true, data: row })}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          {!row.banner ? (
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                onClick={() => setConfirmOpen({ open: true, id: row.id })}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </TableCell>
      </TableRow>
      <TableRow key={`collapse-${row.id}`}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={openCollapse === row.id} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Masonry
                columns={[...Array(columns).keys()]}
                // page={banners.page}
                // count={banners.count}
                // loadData={loadMore}
                // withPagination
              >
                {banners?.results?.map((banner) => {
                  return (
                    <BannerPopover
                      key={banner.id}
                      banner={banner}
                      refetchBanners={refetchBanners}
                    />
                  );
                })}
              </Masonry>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function EnhancedTable({ reload }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [openCollapse, setOpenCollapse] = React.useState(null);
  const [openEdit, setOpenEdit] = useState({ open: false, data: null });
  const [openMerge, setOpenMerge] = useState({ open: false, data: null });
  const [confirmOpen, setConfirmOpen] = useState({ open: false, id: null });
  const [multiCountry, setMultiCountry] = React.useState(false);
  const [openDetails, setOpenDetails] = useState({
    open: false,
    campaign: null,
  });
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    search: "",
    brand: "",
    advertiser: "",
    startDate: moment().subtract(6, "days"),
    endDate: moment(),
    label: "Last 7 Days",
  });
  const [search, setSearch] = useState({
    brand: "",
    advertiser: "",
  });
  const [pagination, setPagination] = useState(1);
  const [{ data: rows, error }, refetch] = useAxios({
    url: "/campaigns/",
    params: {
      multi_countries: multiCountry,
      page: pagination,
      page_size: 15,
      search: filters.search,
      brand__in: filters.brand,
      advertiser__in: filters.advertiser,
      date_start__gte: filters.startDate
        ? moment(filters.startDate).format("YYYY-MM-DD")
        : "",
      date_start__lte: filters.endDate
        ? moment(filters.endDate).format("YYYY-MM-DD")
        : "",
    },
  });

  const [{ data: brands }] = useAxios({
    url: "/brands/",
    params: {
      page_size: 15,
      search: search.brand,
    },
  });
  const [{ data: advertisers }] = useAxios({
    url: "/advertisers/",
    params: {
      page_size: 15,
      search: search.advertiser,
    },
  });
  const [{ data: banners }, refetchBanners] = useAxios(
    {
      url: "/banners/",
      params: {
        campaign: openCollapse || "",
      },
    },
    { manual: !openCollapse }
  );

  const [, deleteCampaign] = useAxios();

  useEffect(() => {
    if (reload) refetch();
  }, [reload, refetch]);

  useEffect(() => {
    if (error) {
      dispatch(
        setSnackNotification("An error has occurred, please try again", "error")
      );
      console.log("error", error);
    }
  }, [error, dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows?.results.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];
  //
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPagination(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination(1);
  };

  // const isSelected = (id) => selected.indexOf(id) !== -1;

  // const emptyRows =
  //   rowsPerPage -
  //   Math.min(
  //     rowsPerPage,
  //     rows?.results.length - (pagination - 1) * rowsPerPage
  //   );

  function setClose(reload) {
    if (reload) refetch();
    setOpenEdit({ open: false, data: null });
  }

  function setCloseMerge(reload) {
    if (reload) refetch();
    setOpenMerge({ open: false, data: null });
  }

  function handleChangeFilter(evt) {
    const { id, value } = evt.target;
    setFilters((prevState) => ({ ...prevState, [id]: value }));
    setPagination(1);
  }

  function handleChange(value, name) {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value && value.id,
    }));
    setPagination(1);
  }

  function handleChangeSearch(event) {
    const { value, name } = event.target;
    setSearch((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleDeleteCampaign() {
    if (confirmOpen.id)
      deleteCampaign({ url: `/campaigns/${confirmOpen.id}`, method: "DELETE" })
        .then(() => {
          dispatch(
            setSnackNotification("Operation successfully completed.", "success")
          );
          refetch();
          setConfirmOpen({ open: false, id: null });
        })
        .catch((err) => {
          dispatch(
            setSnackNotification(
              "An error has occurred, please try again",
              "error"
            )
          );
          console.log("error", err);
        });
  }

  function handleDateRange(evt, picker) {
    setFilters((prevState) => ({
      ...prevState,
      startDate: picker.chosenLabel === "All" ? null : picker.startDate,
      endDate: picker.chosenLabel === "All" ? null : picker.endDate,
      label: picker.chosenLabel,
    }));
  }

  return (
    <Paper p={4}>
      {/*<EnhancedTableToolbar numSelected={selected.length} name="Campaigns" />*/}
      <Grid ml={2} container alignItems="center" spacing={1}>
        <Grid item>Single Country</Grid>
        <Grid item>
          <Switch
            checked={multiCountry}
            // checked={state.checkedC}
            onChange={() => setMultiCountry((prevState) => !prevState)}
            name="Multi-Country"
            color="primary"
          />
        </Grid>
        <Grid item>Multi Country</Grid>
      </Grid>
      <Grid container spacing={3}>
        <TextField
          m={2}
          id="search"
          label="Type to Search"
          type="search"
          onChange={handleChangeFilter}
        />
        <Autocomplete
          id="brand"
          name="brand"
          // size="small"
          m={2}
          options={brands?.results}
          // style={{ margin: "8px" }}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          onChange={(evt, value) => handleChange(value, "brand")}
          renderInput={(params) => (
            <TextField
              {...params}
              name="brand"
              label="Brand"
              // variant="outlined"
              onChange={handleChangeSearch}
            />
          )}
        />
        <Autocomplete
          id="advertiser"
          name="advertiser"
          // size="small"
          options={advertisers?.results}
          m={2}
          // style={{ margin: "8px" }}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          onChange={(evt, value) => handleChange(value, "advertiser")}
          renderInput={(params) => (
            <TextField
              {...params}
              name="advertiser"
              label="Advertiser"
              // variant="outlined"
              onChange={handleChangeSearch}
            />
          )}
        />
        <DateRangePicker
          initialSettings={{
            startDate: filters.startDate,
            endDate: filters.endDate,
            ranges: {
              // Today: [moment(), moment()],
              // Yesterday: [
              //   moment().subtract(1, "days"),
              //   moment().subtract(1, "days"),
              // ],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              // "This Month": [
              //   moment().startOf("month"),
              //   moment().endOf("month"),
              // ],
              // "Last Month": [
              //   moment().subtract(1, "month").startOf("month"),
              //   moment().subtract(1, "month").endOf("month"),
              // ],
              All: [moment(), moment()],
            },
            // alwaysShowCalendars: true,
          }}
          onApply={handleDateRange}
        >
          <Button variant="contained" color="primary" m="auto" mr={2}>
            {filters.label === "Custom Range"
              ? `${moment(filters.startDate).format(
                  "[From:] MM/DD/YY"
                )} ${moment(filters.endDate).format("[To:] MM/DD/YY")}`
              : filters.label}
          </Button>
        </DateRangePicker>
      </Grid>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows?.results?.length}
            headCells={headCells}
          />
          <TableBody>
            {rows &&
              stableSort(rows.results, getComparator(order, orderBy)).map(
                (row) => {
                  // const isItemSelected = isSelected(row.id);
                  // const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <Row
                      key={row.id}
                      row={row}
                      refetchBanners={refetchBanners}
                      banners={banners}
                      setConfirmOpen={setConfirmOpen}
                      openCollapse={openCollapse}
                      setOpenCollapse={setOpenCollapse}
                      setOpenEdit={setOpenEdit}
                      setOpenDetails={setOpenDetails}
                    />
                  );
                }
              )}
            {/*{emptyRows > 0 && (*/}
            {/*  <TableRow style={{ height: 53 * emptyRows }}>*/}
            {/*    <TableCell colSpan={7} />*/}
            {/*  </TableRow>*/}
            {/*)}*/}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 25, 50]}
        component="div"
        count={rows?.count || 0}
        rowsPerPage={rowsPerPage}
        page={pagination - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {openEdit.open ? (
        <NewCampaign
          open={openEdit.open}
          setClose={setClose}
          editData={openEdit.data}
        />
      ) : null}
      {openMerge.open ? (
        <MergeCampaigns
          open={openMerge.open}
          setClose={setCloseMerge}
          data={openMerge.data}
        />
      ) : null}
      {openDetails.open ? (
        <CampaignDetails
          open={openDetails.open}
          setOpen={setOpenDetails}
          campaign={openDetails.campaign}
          editCountry
        />
      ) : null}
      <ConfirmDialog
        title="Delete Campaign?"
        open={confirmOpen.open}
        handleClose={() => setConfirmOpen({ open: false, id: null })}
        onConfirm={handleDeleteCampaign}
      >
        {`Are you sure you want to remove this campaign?`}
      </ConfirmDialog>
    </Paper>
  );
}

function Campaigns({ width }) {
  const [openNew, setOpenNew] = useState(false);
  return (
    <React.Fragment>
      <Helmet title="Campaigns" />

      <MainContent
        px={isWidthUp("lg", width) ? 12 : 5}
        py={isWidthUp("lg", width) ? 12 : 5}
        id="masonry-main"
      >
        <Grid justifyContent="space-between" container spacing={4}>
          <Grid item>
            <Typography variant="h3" gutterBottom display="inline">
              Campaigns
            </Typography>

            {/*<Breadcrumbs aria-label="Breadcrumb" mt={2}>*/}
            {/*  <Link component={NavLink} exact to="/">*/}
            {/*    Dashboard*/}
            {/*  </Link>*/}
            {/*  <Link component={NavLink} exact to="/">*/}
            {/*    Pages*/}
            {/*  </Link>*/}
            {/*  <Typography>Invoices</Typography>*/}
            {/*</Breadcrumbs>*/}
          </Grid>
          <Grid item>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenNew(true)}
              >
                <AddIcon />
                New Campaign
              </Button>
            </div>
          </Grid>
        </Grid>

        <Divider my={6} />

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable reload={!openNew} />
          </Grid>
        </Grid>
      </MainContent>
      {openNew ? (
        <NewCampaign open={openNew} setClose={() => setOpenNew(false)} />
      ) : null}
    </React.Fragment>
  );
}

export default withWidth()(Campaigns);
