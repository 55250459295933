import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Popover,
  TextField as MuiTextField,
} from "@material-ui/core";
import styled from "styled-components/macro";
import EnhancedTable from "../Classify/components/EnhancedTable";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import { CloseOutlined } from "@material-ui/icons";
import { setSnackNotification } from "../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import useAxios from "axios-hooks";
import Masonry from "../../components/Masonry";
import ProgressiveImageSize from "../../components/ProgressiveImageSize";

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  min-width: -webkit-fill-available;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "80%",
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
    backgroundColor: "unset",
  },
  paperPop: {
    zIndex: 100,
    width: "600px",
    height: "100%",
    maxHeight: "700px",
    padding: "20px",
    border: "2px solid black",
    overflow: "scroll",
  },
}));

function SearchCampaign({ handleSearch, open, setOpen, countryFilter }) {
  const [filters, setFilters] = useState({
    category: "",
    brand: "",
    campaign: "",
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    category: null,
    brand: null,
    campaign: null,
  });
  const [campaignPreview, setCampaignPreview] = useState([]);
  const [openedPopover, setOpenedPopover] = useState({
    openedId: null,
    anchorEl: null,
  });
  const [, execute, cancelRequest] = useAxios({}, { manual: true });
  const [pagination, setPagination] = useState({
    campaign: 1,
    brand: 1,
    category: 1,
  });
  const [
    { data: categories, error: errorCategories },
    // refetchCategories,
  ] = useAxios({
    url: "/categories/",
    params: {
      page: pagination.category,
      page_size: 15,
      search: filters.category,
    },
  });
  const [
    { data: brands, error: errorBrands },
    // refetchBrands,
  ] = useAxios({
    url: "/brands/",
    params: {
      page: pagination.brand,
      page_size: 15,
      search: filters.brand,
    },
  });
  const [
    { data: campaigns, error: errorCampaign },
    // refetchCampaigns,
  ] = useAxios(
    {
      url: "/campaigns/",
      params: {
        page: pagination.campaign,
        page_size: 15,
        search: filters.campaign,
        in_country: countryFilter === "all" ? "" : countryFilter,
        category__in: data.category || "",
        brand__in: data.brand || "",
      },
    },
    { manual: !(data.category || data.brand) }
  );

  useEffect(() => {
    if (errorCategories || errorCampaign || errorBrands) {
      dispatch(
        setSnackNotification("An error has occurred, please try again", "error")
      );
      console.log("error", errorCategories || errorCampaign || errorBrands);
    }
  }, [errorCategories, errorCampaign, errorBrands, dispatch]);

  function handleChangeFilter(evt) {
    const { id, value } = evt.target;
    setFilters((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setPagination((prevState) => ({ ...prevState, [id]: 1 }));
  }

  function handleClick(key, value) {
    setData((prevState) => ({
      ...prevState,
      [key]: prevState[key] === value ? null : value,
    }));
  }

  function handleClickCategory(key, value) {
    setData((prevState) => ({
      ...prevState,
      category: prevState.category === value ? null : value,
      campaign: null,
    }));
    setPagination((prevState) => ({ ...prevState, campaign: 1 }));
  }

  function handleClickBrand(key, value) {
    setData((prevState) => ({
      ...prevState,
      brand: prevState.brand === value ? null : value,
      campaign: null,
    }));
    setPagination((prevState) => ({ ...prevState, campaign: 1 }));
  }

  function onAccept() {
    const campaign = campaigns?.results.find((x) => x.id === data.campaign);
    handleSearch(campaign);
    onClose();
  }

  function onClose() {
    setData({
      category: null,
      campaign: null,
      brand: null,
    });
    setOpen(false);
  }

  function handlePopoverClose() {
    cancelRequest();
    setOpenedPopover({ openedId: null, anchorEl: null });
  }

  async function handlePopoverOpen(id, event) {
    const { currentTarget } = event;
    const { data: bannersPreview } = await execute({
      url: "/banners/",
      params: {
        campaign: id,
        page_size: 5,
      },
    });
    if (bannersPreview) {
      setCampaignPreview(bannersPreview.results);
      setOpenedPopover({ openedId: id, anchorEl: currentTarget });
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        maxWidth={"md"}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle id="form-dialog-title">
          Search Campaign
          <CloseOutlined
            style={{ float: "right", cursor: "pointer" }}
            onClick={onClose}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                m={2}
                id="brand"
                label="Type to Filter Brand"
                type="search"
                onChange={handleChangeFilter}
              />
              <EnhancedTable
                hideCheckbox
                rows={brands?.results}
                name="brand"
                selected={data.brand}
                handleClick={handleClickBrand}
                page={pagination.brand}
                loadData={(p) =>
                  setPagination((prevState) => ({ ...prevState, brand: p }))
                }
                showPagination
                count={brands?.count}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                m={2}
                id="category"
                label="Type to Filter Category"
                type="search"
                onChange={handleChangeFilter}
              />
              <EnhancedTable
                hideCheckbox
                rows={categories?.results}
                name="category"
                selected={data.category}
                handleClick={handleClickCategory}
                page={pagination.category}
                loadData={(p) =>
                  setPagination((prevState) => ({ ...prevState, category: p }))
                }
                showPagination
                count={categories?.count}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                m={2}
                id="campaign"
                label="Type to Filter Campaign"
                type="search"
                value={filters.campaign}
                onChange={handleChangeFilter}
              />
              <EnhancedTable
                hideCheckbox
                rows={data.category || data.brand ? campaigns?.results : []}
                name="campaign"
                selected={data.campaign}
                handleClick={handleClick}
                page={data.category || data.brand ? pagination.campaign : 1}
                loadData={(p) =>
                  setPagination((prevState) => ({ ...prevState, campaign: p }))
                }
                showPagination
                count={data.category || data.brand ? campaigns?.count : 0}
                onHoverEnter={handlePopoverOpen}
                onHoverLeave={handlePopoverClose}
              />
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.popoverContent,
                }}
                open={!!openedPopover.openedId}
                anchorEl={openedPopover.anchorEl}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Paper className={classes.paperPop}>
                  <Masonry columns={[1, 2]}>
                    {campaignPreview &&
                      campaignPreview.map((banner) => {
                        return (
                          <ProgressiveImageSize
                            src={
                              banner.type.indexOf("video") !== -1
                                ? banner.thumb
                                : banner.media
                            }
                            alt={banner.id}
                            size={banner.size}
                            type={banner.type}
                            key={banner.id}
                          />
                        );
                      })}
                  </Masonry>
                </Paper>
              </Popover>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            onClick={onAccept}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!data.campaign}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SearchCampaign;
