import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import Helmet from "react-helmet";

import {
  Button,
  // Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField as MuiTextField,
  Typography,
  withWidth,
  Chip,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Edit as EditIcon,
  // Delete as DeleteIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import { setSnackNotification } from "../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import { isWidthUp } from "@material-ui/core/withWidth";
import {
  EnhancedTableHead,
  EnhancedTableToolbar,
  stableSort,
  getComparator,
} from "../../utils/tablesUtils";
import NewUser from "./components/NewUser";
import useAxios from "axios-hooks";
import ConfirmDialog from "../../components/ConfirmDialog";

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  width: 300px;
  margin-bottom: 20px;
`;

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const StatusChip = styled(Chip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.rgbcolor};
  color: ${(props) => props.theme.palette.common.white};
`;

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "username", alignment: "left", label: "Username" },
  { id: "status", alignment: "left", label: "Status" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "actions", alignment: "right", label: "Actions" },
];

function EnhancedTable({ reload }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const dispatch = useDispatch();
  const [openEdit, setOpenEdit] = useState({ open: false, data: null });
  const [confirmOpen, setConfirmOpen] = useState({ open: false, id: null });

  const [filters, setFilters] = useState("");
  const [pagination, setPagination] = useState(1);
  const [{ data: rows, error }, refetch] = useAxios({
    url: "/users/",
    params: {
      page: pagination,
      page_size: rowsPerPage,
      search: filters,
    },
  });

  // const [, executeDelete] = useAxios(
  //   {
  //     url: "/users/",
  //     method: "DELETE",
  //   },
  //   { manual: true }
  // );

  useEffect(() => {
    if (reload) refetch();
  }, [reload, refetch]);

  useEffect(() => {
    if (error) {
      dispatch(
        setSnackNotification("An error has occurred, please try again", "error")
      );
      console.log("error", error);
    }
  }, [error, dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.results.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];
  //
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPagination(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination(1);
  };

  // const isSelected = (id) => selected.indexOf(id) !== -1;

  // const emptyRows =
  //   rowsPerPage -
  //   Math.min(
  //     rowsPerPage,
  //     rows?.results.length - (pagination - 1) * rowsPerPage
  //   );

  function setClose(reload) {
    if (reload) refetch();
    setOpenEdit({ open: false, data: null });
  }

  function handleChangeFilter(evt) {
    const { value } = evt.target;
    setFilters(value);
    setPagination(1);
  }

  function deleteItem() {
    // executeDelete({})
  }

  return (
    <div>
      <Paper p={4}>
        <EnhancedTableToolbar numSelected={selected.length} name="Users" />
        <TextField
          m={2}
          id="user"
          label="Type to Search"
          type="search"
          onChange={handleChangeFilter}
        />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.results.length}
              headCells={headCells}
            />
            <TableBody>
              {rows &&
                stableSort(rows.results, getComparator(order, orderBy)).map(
                  (row, index) => {
                    // const isItemSelected = isSelected(row.id);
                    // const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        // selected={isItemSelected}
                      >
                        {/*<TableCell padding="checkbox">*/}
                        {/*  <Checkbox*/}
                        {/*    checked={isItemSelected}*/}
                        {/*    inputProps={{ "aria-labelledby": labelId }}*/}
                        {/*    onClick={(event) => handleClick(event, row.id)}*/}
                        {/*  />*/}
                        {/*</TableCell>*/}
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="left">{row.username}</TableCell>
                        <TableCell align="left">
                          <StatusChip
                            size="small"
                            label={row.is_active ? "Active" : "Inactive"}
                            rgbcolor={
                              row.is_active
                                ? "rgb(76,175,80)"
                                : "rgb(217,16,35)"
                            }
                          />
                        </TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="right">
                          {/*<IconButton aria-label="delete">*/}
                          {/*  <DeleteIcon*/}
                          {/*    onClick={() =>*/}
                          {/*      setOpenEdit({ open: true, data: row })*/}
                          {/*    }*/}
                          {/*  />*/}
                          {/*</IconButton>*/}
                          <IconButton
                            aria-label="Edit"
                            onClick={() =>
                              setOpenEdit({ open: true, data: row })
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              {/*{emptyRows > 0 && (*/}
              {/*  <TableRow style={{ height: 53 * emptyRows }}>*/}
              {/*    <TableCell colSpan={7} />*/}
              {/*  </TableRow>*/}
              {/*)}*/}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50]}
          component="div"
          count={rows?.count || 0}
          rowsPerPage={rowsPerPage}
          page={pagination - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {openEdit.open ? (
          <NewUser
            open={openEdit.open}
            setClose={setClose}
            editData={openEdit.data}
          />
        ) : null}
        {confirmOpen.open ? (
          <ConfirmDialog
            title="Delete?"
            open={confirmOpen.open}
            handleClose={() => setConfirmOpen({ open: false, id: null })}
            onConfirm={deleteItem}
          >
            Are you sure you want to remove this user?
          </ConfirmDialog>
        ) : null}
      </Paper>
    </div>
  );
}

function Users({ width }) {
  const [openNew, setOpenNew] = useState(false);

  return (
    <React.Fragment>
      <Helmet title="Users" />

      <MainContent
        px={isWidthUp("lg", width) ? 12 : 5}
        py={isWidthUp("lg", width) ? 12 : 5}
        id="masonry-main"
      >
        <Grid justifyContent="space-between" container spacing={4}>
          <Grid item>
            <Typography variant="h3" gutterBottom display="inline">
              Users
            </Typography>

            {/*<Breadcrumbs aria-label="Breadcrumb" mt={2}>*/}
            {/*  <Link component={NavLink} exact to="/">*/}
            {/*    Dashboard*/}
            {/*  </Link>*/}
            {/*  <Link component={NavLink} exact to="/">*/}
            {/*    Pages*/}
            {/*  </Link>*/}
            {/*  <Typography>Invoices</Typography>*/}
            {/*</Breadcrumbs>*/}
          </Grid>
          <Grid item>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenNew(true)}
              >
                <AddIcon />
                New User
              </Button>
            </div>
          </Grid>
        </Grid>

        <Divider my={6} />

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <EnhancedTable reload={!openNew} />
          </Grid>
        </Grid>
      </MainContent>
      {openNew ? (
        <NewUser open={openNew} setClose={() => setOpenNew(false)} />
      ) : null}
    </React.Fragment>
  );
}

export default withWidth()(Users);
