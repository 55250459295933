import * as types from "../../constants";

const initialState = {
  search: "",
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.FILTERS_SET:
      return {
        ...state,
        ...actions,
      };

    case types.FILTERS_CLEAN:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
