import * as types from "../../constants";

const initialState = {
  open: false,
  msg: "",
  severity: "",
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SNACK_NOTIFICATION_SET:
      return {
        ...state,
        ...actions,
      };

    case types.SNACK_NOTIFICATION_CLEAR:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
