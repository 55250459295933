import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Skeleton from "@material-ui/lab/Skeleton";

const ImgStyled = styled.img`
  border: ${(props) =>
    props.selected ? "4px solid #EA7126" : "1px solid gray"};
  padding: ${(props) => (props.selected ? "5px" : "unset")};
  display: block;
  width: 100%;
  height: ${(props) => props.height}px;
  object-fit: cover;
`;

const VideoDiv = styled.div`
  border: ${(props) =>
    props.selected ? "4px solid #EA7126" : "1px solid gray"};
  padding: ${(props) => (props.selected ? "5px" : "unset")};
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:after {
    width: 0;
    height: 0;
    transform: translate(-5px, -15px);
    border: 16px solid transparent;
    border-left: 20px solid #fff;
    -webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
  }
  &:before {
    width: 60px;
    height: 60px;
    border: 3px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(black, 0.18);
    background: rgba(black, 0.42);
  }
`;

const ImgContainer = styled.div`
  // border: ${(props) => (props.selected ? "4px solid #EA7126" : "unset")};
  // padding: ${(props) => (props.selected ? "5px" : "unset")};
  width: 100%;
  grid-row-end: span ${(props) => props.gridRowSpan};
`;

function ProgressiveImageSize({
  src,
  alt,
  children,
  selected,
  size,
  type,
  ...rest
}) {
  const [image, setImage] = useState({
    image: "",
    height: 350,
    loading: true,
    error: false,
  });

  let mediaType = type?.split("/")[0];

  const fetchImage = (img, alt) => {
    if (img === null) return;
    const image = new Image();
    image.onload = function () {
      const widthEle =
        document.getElementById(`container-${alt}`) &&
        document.getElementById(`container-${alt}`).clientWidth;

      const h = (this.height * widthEle) / this.width;
      setImage({ image: image.src, loading: false, error: false, height: h });
    };
    image.onerror = function () {
      setImage((prevState) => ({
        ...prevState,
        image: "",
        loading: false,
        error: true,
      }));
    };
    image.onabort = function () {
      setImage((prevState) => ({
        ...prevState,
        image: "",
        loading: false,
        error: true,
      }));
    };
    image.src = img;
  };

  useEffect(() => {
    fetchImage(src, alt);
    return () => fetchImage("");
  }, [src, alt]);

  function renderContent() {
    if (mediaType === "video") {
      return image.loading ? (
        <Skeleton
          height={350}
          width="100%"
          animation="wave"
          variant="rect"
          selected={selected}
        />
      ) : (
        <VideoDiv>
          <ImgStyled
            src={src}
            alt={alt}
            height={image.height}
            selected={selected}
          />
          {children}
        </VideoDiv>
      );
    } else {
      return image.loading ? (
        <Skeleton
          height={350}
          width="100%"
          animation="wave"
          variant="rect"
          selected={selected}
        />
      ) : (
        <ImgStyled
          src={src}
          alt={alt}
          height={image.height}
          selected={selected}
        />
      );
    }
  }

  return (
    <ImgContainer
      // selected={selected}
      id={`container-${alt}`}
      gridRowSpan={Math.ceil((image.height + 10) / (2 + 10))}
      {...rest}
    >
      {renderContent()}
    </ImgContainer>
  );
}

export default ProgressiveImageSize;
