import React, { useState } from "react";
import styled from "styled-components/macro";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link as MuiLink,
  IconButton,
  DialogTitle,
  DialogContent,
  TextField as MuiTextField,
  DialogActions,
  Button,
  Dialog,
} from "@material-ui/core";

import {
  CheckCircle as CheckCircleIcon,
  Edit as EditIcon,
} from "@material-ui/icons";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import useAxios from "axios-hooks";
import { setSnackNotification } from "../../redux/actions/snackAction";
import { useDispatch } from "react-redux";

const CustomTableCell = withStyles(() => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Link = styled(MuiLink)`
  overflow-wrap: anywhere;
`;

const useStyles = makeStyles((theme) => ({
  customCell: {
    textTransform: "capitalize",
    display: "table-cell",
    flexDirection: "row",
  },
  subName: {
    fontSize: "10px",
    color: theme.palette.secondary.main,
    alignSelf: "flex-end",
    margin: "0 5px",
  },
}));

function CampaignTable({ data, url, editCountry }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openEdit, setOpenEdit] = useState(false);
  const [country, setCountry] = useState(null);

  const [{ data: countries }] = useAxios(
    {
      url: "/countries/",
    },
    { manual: !openEdit }
  );

  const [, setCountries] = useAxios({ manual: true });

  function setCountriesCampaign() {
    setCountries({
      url: `/campaigns/${data.id}/set_country/`,
      method: "POST",
      data: {
        country: country?.id || "",
      },
    })
      .then(() => {
        dispatch(
          setSnackNotification("Operation successfully completed.", "success")
        );
        setOpenEdit(false);
      })
      .catch((error) => {
        dispatch(
          setSnackNotification(
            "An error has occurred, please try again",
            "error"
          )
        );
        console.log("error", error);
      });
  }

  return data ? (
    <>
      <Table size="small">
        <TableBody>
          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Campaign</b>
            </CustomTableCell>
            <CustomTableCell className={classes.customCell}>
              {data.name || ""}
            </CustomTableCell>
            <CustomTableCell>
              {/*<IconButton m={2} size="small" aria-label="View">*/}
              {/*  <EditIcon color="primary" onClick={onEditCampaign} />*/}
              {/*</IconButton>*/}
            </CustomTableCell>
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Advertiser</b>
            </CustomTableCell>
            <CustomTableCell className={classes.customCell}>
              {data.advertiser?.name}
            </CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Category</b>
            </CustomTableCell>
            <CustomTableCell className={classes.customCell}>
              {data.category?.name || ""}
            </CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Brand</b>
            </CustomTableCell>
            <CustomTableCell className={classes.customCell}>
              {data.brand?.name || ""}
            </CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>

          {/*<CustomTableRow>*/}
          {/*  <CustomTableCell component="th" scope="row">*/}
          {/*    <b>AdServer</b>*/}
          {/*  </CustomTableCell>*/}
          {/*  <CustomTableCell>{data.adServer}</CustomTableCell>*/}
          {/*  <CustomTableCell />*/}
          {/*</CustomTableRow>*/}

          {/*<CustomTableRow>*/}
          {/*  <CustomTableCell component="th" scope="row">*/}
          {/*    <b>Language</b>*/}
          {/*  </CustomTableCell>*/}
          {/*  <CustomTableCell>{data.language?.name || ""}</CustomTableCell>*/}
          {/*  <CustomTableCell>*/}
          {/*    /!*<IconButton m={2} size="small" aria-label="View">*!/*/}
          {/*    /!*  <EditIcon color="inherit" />*!/*/}
          {/*    /!*</IconButton>*!/*/}
          {/*  </CustomTableCell>*/}
          {/*</CustomTableRow>*/}

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Active</b>
            </CustomTableCell>
            <CustomTableCell>
              <CheckCircleIcon htmlColor="#00A854" />
            </CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Countries</b>
            </CustomTableCell>
            <CustomTableCell>
              {data?.countries
                ?.map((x) => x.name)
                ?.toString()
                .replaceAll(",", ", ")}
            </CustomTableCell>
            <CustomTableCell>
              {editCountry ? (
                <IconButton
                  m={2}
                  size="small"
                  aria-label="Edit"
                  onClick={() => setOpenEdit(true)}
                >
                  <EditIcon color="primary" />
                </IconButton>
              ) : null}
            </CustomTableCell>
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Destination URL</b>
            </CustomTableCell>
            <CustomTableCell>
              {url ? (
                <Link href={url} color="initial" target="_blank">
                  {url}
                </Link>
              ) : null}
            </CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>
        </TableBody>
      </Table>
      <Dialog
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Edit Country</DialogTitle>
        <DialogContent>
          <Autocomplete
            id="country"
            size="small"
            style={{ margin: "10px" }}
            options={countries?.results || []}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            value={country}
            // onInputChange={onInputAutoChange}
            onChange={(evt, val) => setCountry(val)}
            renderInput={(params) => (
              <MuiTextField
                {...params}
                // variant="outlined"
                size="small"
                style={{ height: "auto" }}
                label="Country"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenEdit(false)}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={setCountriesCampaign}
            color="primary"
            autoFocus
            variant="contained"
          >
            Edit & Recalculate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
}

function BannerTable({ data }) {
  const classes = useStyles();
  return (
    data && (
      <Table size="small">
        <TableBody>
          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Title</b>
            </CustomTableCell>
            <CustomTableCell className={classes.customCell}>
              {data.name || ""}
            </CustomTableCell>
            <CustomTableCell></CustomTableCell>
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Product</b>
            </CustomTableCell>
            <CustomTableCell className={classes.customCell}>
              {data.product?.name}
            </CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Advertiser</b>
            </CustomTableCell>
            <CustomTableCell className={classes.customCell}>
              {data.advertiser?.name || ""}
            </CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Brand</b>
            </CustomTableCell>
            <CustomTableCell className={classes.customCell}>
              {data.brand?.name || ""}
            </CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>AdServer</b>
            </CustomTableCell>
            <CustomTableCell>{data.adServer}</CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Language</b>
            </CustomTableCell>
            <CustomTableCell>{data.language?.name || ""}</CustomTableCell>
            <CustomTableCell></CustomTableCell>
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Active</b>
            </CustomTableCell>
            <CustomTableCell>
              <CheckCircleIcon htmlColor="#00A854" />
            </CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Region</b>
            </CustomTableCell>
            <CustomTableCell>{data.region?.name}</CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>

          <CustomTableRow>
            <CustomTableCell component="th" scope="row">
              <b>Destination URL</b>
            </CustomTableCell>
            <CustomTableCell>
              <Link href={data.link} color="initial" target="_blank">
                {data.link}
              </Link>
            </CustomTableCell>
            <CustomTableCell />
          </CustomTableRow>
        </TableBody>
      </Table>
    )
  );
}

export { BannerTable, CampaignTable };
