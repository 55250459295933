import React, { useEffect, useState } from "react";
import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Paper,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  FilterList,
  CloseOutlined,
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import ModalSelect from "./ModalSelect";
import * as ClassifyService from "../../../services/classifyService";
import { setSnackNotification } from "../../../redux/actions/snackAction";
import Masonry from "../../../components/Masonry";
import useAxios from "axios-hooks";
import ConfirmDialog from "../../../components/ConfirmDialog";
import capitalize from "../../../utils/capitalize";
import BannerPopover from "../../common/BannerPopover";
import { Autocomplete } from "@material-ui/lab";

const Typography = styled(MuiTypography)(spacing);

const Button = styled(MuiButton)(spacing);

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  min-width: -webkit-fill-available;
  // width: 100%;
`;

const SmallButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  height: fit-content;
  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;

const useStyles = makeStyles(() => ({
  paper: {
    height: "auto",
    width: "70%",
  },
  paperMas: {
    padding: "20px",
    overflow: "scroll",
  },
  inactive: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    color: "white",
  },
  topItems: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto",
    gridColumnGap: "10px",
  },
}));

function NewCampaign({ open, setClose, editData }) {
  const classes = useStyles();
  const [view, setView] = useState("module");
  const [data, setData] = useState({
    name: "",
    brand: null,
    category: null,
    advertiser: null,
    country: null,
  });
  const [openModal, setOpenModal] = useState({
    open: false,
    url: "",
    type: "",
  });
  const [{ data: banners, loading }, refetchBanners] = useAxios(
    {
      url: "/banners/",
      params: {
        campaign: editData?.id,
        page_size: 15,
      },
    },
    { manual: !editData }
  );

  const [{ data: campaign }] = useAxios(
    {
      url: `/campaigns/${editData?.id}`,
    },
    { manual: !editData }
  );

  const [, executeRecalculate] = useAxios();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [{ data: countries }] = useAxios({
    url: "/countries/",
  });

  const [, setCountries] = useAxios({ manual: true });

  const user = useSelector((state) => state.authReducer.user);
  const isAdmin = !!user.groups.some((x) => ["admin"].indexOf(x) >= 0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (editData && campaign) {
      setData((prev) => ({
        ...prev,
        name: campaign.name,
        brand: campaign.brand,
        category: campaign.category,
        advertiser: campaign.advertiser,
      }));
    }
  }, [editData, campaign]);

  function setCountriesCampaign() {
    setCountries({
      url: `/campaigns/${editData.id}/set_country/`,
      method: "POST",
      data: {
        country: data.country?.id || "",
      },
    })
      .then(() => {
        dispatch(
          setSnackNotification("Operation successfully completed.", "success")
        );
        onClose(true);
      })
      .catch((error) => {
        dispatch(
          setSnackNotification(
            "An error has occurred, please try again",
            "error"
          )
        );
        console.log("error", error);
      });
  }

  function onAccept() {
    const { id } = editData || {};
    if (
      editData &&
      editData.category.id !== data.category.id &&
      banners?.results.length
    ) {
      ClassifyService.addClassify(`/banners/?campaign=${editData.id}`, {
        status: "review",
        campaign: null,
      })
        .then(() => {
          ClassifyService.postWithFormData(
            `/campaigns/${id || ""}`,
            {
              name: capitalize(data.name),
              brand: data.brand.id,
              advertiser: data.advertiser.id,
              category: data.category.id,
            },
            !!id
          )
            .then(() => {
              if (editData) setCountriesCampaign();
              else {
                dispatch(
                  setSnackNotification(
                    "Operation successfully completed.",
                    "success"
                  )
                );
                onClose(true);
              }
            })
            .catch((error) => {
              dispatch(
                setSnackNotification(
                  "An error has occurred, please try again",
                  "error"
                )
              );
              console.log("error", error);
            });
        })
        .catch((error) => {
          dispatch(
            setSnackNotification(
              "An error has occurred, please try again",
              "error"
            )
          );
          console.log("error", error);
        });
    } else
      ClassifyService.postWithFormData(
        `/campaigns/${id || ""}`,
        {
          name: capitalize(data.name),
          brand: data.brand.id,
          advertiser: data.advertiser.id,
          category: data.category.id,
        },
        !!id
      )
        .then(() => {
          if (editData) setCountriesCampaign();
          else {
            dispatch(
              setSnackNotification(
                "Operation successfully completed.",
                "success"
              )
            );
            onClose(true);
          }
        })
        .catch((error) => {
          dispatch(
            setSnackNotification(
              "An error has occurred, please try again",
              "error"
            )
          );
          console.log("error", error);
        });
  }

  function handleChange(evt) {
    const { id, value } = evt.target;
    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  function handleChangeAuto(id, value) {
    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  function onClose() {
    setData({
      name: "",
      brand: null,
      category: null,
      advertiser: null,
      country: null,
    });
    setClose();
  }

  function handleOk(key, value) {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setOpenModal({ open: false, url: "", type: "" });
  }

  function handleView(value) {
    if (view !== value) setView(value);
  }

  function onConfirm() {
    setConfirmOpen(true);
  }

  function handleRecalculate() {
    editData?.id &&
      executeRecalculate({
        url: `/campaigns/${editData.id}/calc_stats/`,
        // params: {}
        method: "POST",
      })
        .then(() => {
          dispatch(
            setSnackNotification("Operation successfully completed.", "success")
          );
        })
        .catch((err) => {
          dispatch(
            setSnackNotification(
              "An error has occurred, please try again",
              "error"
            )
          );
          console.log("error", err);
        });
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={false}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle id="form-dialog-title">
          {editData ? "Edit Campaign" : "New Campaign"}
          <CloseOutlined
            style={{ float: "right", cursor: "pointer" }}
            onClick={onClose}
          />
        </DialogTitle>
        <DialogContent>
          {/*<Backdrop className={classes.backdrop} open={loading}>*/}
          {/*  <CircularProgress color="inherit" />*/}
          {/*</Backdrop>*/}
          <Grid container spacing={3}>
            <Grid item xs={6} md={4}>
              <TextField
                m={2}
                required
                id="name"
                label="Name"
                value={data.name}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={6} md={8} style={{ display: "flex" }}>
              {editData?.id && isAdmin ? (
                <Button
                  color="primary"
                  variant="contained"
                  m={"auto 10px auto auto"}
                  onClick={handleRecalculate}
                >
                  Re-Calculate
                </Button>
              ) : null}
            </Grid>

            <Grid item xs={9} md={6}>
              <TextField
                m={2}
                id="brand"
                label="Select Brand"
                // type="search"
                value={data?.brand?.name || ""}
                readOnly
                onClick={() =>
                  setOpenModal({
                    open: true,
                    url: "/brands/",
                    type: "brand",
                  })
                }
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <FilterList
                          cursor="pointer"
                          onClick={() =>
                            setOpenModal({
                              open: true,
                              url: "/brands/",
                              type: "brand",
                            })
                          }
                        />
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={9} md={6}>
              <TextField
                m={2}
                id="category"
                label="Select Category"
                // type="search"
                value={data?.category?.name || ""}
                readOnly
                onClick={() =>
                  setOpenModal({
                    open: true,
                    url: "/categories/",
                    type: "category",
                  })
                }
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <FilterList
                          cursor="pointer"
                          onClick={() =>
                            setOpenModal({
                              open: true,
                              url: "/categories/",
                              type: "category",
                            })
                          }
                        />
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={9} md={6}>
              <TextField
                m={2}
                id="advertiser"
                label="Select Advertiser"
                // type="search"
                value={data?.advertiser?.name || ""}
                readOnly
                onClick={() =>
                  setOpenModal({
                    open: true,
                    url: "/advertisers/",
                    type: "advertiser",
                  })
                }
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <FilterList
                          cursor="pointer"
                          onClick={() =>
                            setOpenModal({
                              open: true,
                              url: "/advertisers/",
                              type: "advertiser",
                            })
                          }
                        />
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            </Grid>
            {editData ? (
              <Grid item xs={6} md={6}>
                <Autocomplete
                  id="country"
                  size="small"
                  style={{ margin: "10px" }}
                  options={countries?.results || []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={data.country}
                  // onInputChange={onInputAutoChange}
                  onChange={(evt, val) => handleChangeAuto("country", val)}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      // variant="outlined"
                      size="small"
                      style={{ height: "auto" }}
                      label="Country"
                    />
                  )}
                />
              </Grid>
            ) : null}
            {editData && !loading ? (
              <Grid item xs={12}>
                <Paper className={classes.paperMas}>
                  <div className={classes.topItems}>
                    <Typography variant="h5" p={2}>
                      Ads
                    </Typography>

                    <SmallButton
                      size="small"
                      variant="contained"
                      color="primary"
                      className={view === "list" ? classes.inactive : null}
                      onClick={() => handleView("module")}
                    >
                      <ViewModuleIcon />
                    </SmallButton>
                    <SmallButton
                      size="small"
                      variant="contained"
                      color="primary"
                      className={view === "module" ? classes.inactive : null}
                      onClick={() => handleView("list")}
                    >
                      <ViewListIcon />
                    </SmallButton>
                  </div>
                  <Masonry columns={[1, 2, 3, 4]}>
                    {banners?.results.map((banner) => {
                      return (
                        <BannerPopover
                          banner={banner}
                          refetchBanners={refetchBanners}
                        />
                      );
                    })}
                  </Masonry>
                </Paper>
              </Grid>
            ) : null}
          </Grid>
          {openModal.open ? (
            <ModalSelect
              handleOk={handleOk}
              open={openModal.open}
              setClose={() => setOpenModal({ open: false, url: "", type: "" })}
              url={openModal.url}
              type={openModal.type}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            onClick={() => (!editData ? onAccept() : onConfirm())}
            color="primary"
            variant="contained"
            fullWidth
            disabled={
              !(data.brand && data.category && data.advertiser && data.name)
            }
          >
            {editData ? "Edit" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        title="Edit Campaign?"
        open={confirmOpen}
        handleClose={() => setConfirmOpen(false)}
        onConfirm={onAccept}
      >
        Are you sure you want to edit this campaign?
      </ConfirmDialog>
    </>
  );
}

export default NewCampaign;
