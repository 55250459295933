import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Skeleton from "@material-ui/lab/Skeleton";

const ImgStyled = styled.img`
  display: block;
  margin: ${(props) => props.margin || "unset"};
  object-fit: ${(props) => props.objectFit || "cover"};
  height: ${(props) => props.customHeight || "auto"};
  ${(props) =>
    props.customWidth
      ? `width: ${props.customWidth};`
      : `
        max-width: 100%;
  `}
  ${(props) =>
    props.customMaxHeight ? `max-height: ${props.customMaxHeight};` : ""}
`;

const VideoStyled = styled.video`
  display: block;
  object-fit: cover;
  ${(props) =>
    props.customMaxHeight ? `max-height: ${props.customMaxHeight};` : ""}
  ${(props) =>
    props.customWidth
      ? `width: ${props.customWidth}px;`
      : `
        max-width: 100%;
  `}
`;

function ProgressiveImage({
  src,
  alt,
  type,
  customWidth,
  customHeight,
  customMaxHeight,
  objectFit,
  onMouseEnter,
  onMouseLeave,
  margin,
}) {
  const [image, setImage] = useState({
    image: "",
    loading: true,
    error: false,
  });

  const [, setVideo] = useState({
    video: "",
    loading: true,
    error: false,
  });

  const fetchImage = (img) => {
    if (img === null) return;
    const image = new Image();

    image.onload = () => {
      setImage({ image: image.src, loading: false, error: false });
    };
    image.onerror = () => {
      setImage({ image: "", loading: false, error: true });
    };
    image.onabort = () => {
      setImage({ image: "", loading: false, error: true });
    };
    image.src = img;
  };

  const fetchVideo = (vid) => {
    if (vid === null) return;
    const vidi = document.createElement("video");

    vidi.onloadeddata = () => {
      setVideo({ video: vidi.src, loading: false, error: false });
    };
    vidi.onerror = () => {
      setVideo({ video: "", loading: false, error: true });
    };
    vidi.onabort = () => {
      setVideo({ video: "", loading: false, error: true });
    };
    vidi.src = vid;
  };

  function onMouseOver() {
    document.getElementById(`img-styled-${alt}`).src = src;
  }

  useEffect(() => {
    if (type === "video") {
      fetchVideo(src);
    } else {
      fetchImage(src);
    }
    return () => fetchImage("");
  }, [src, type]);

  if (type === "video") {
    /* return video.loading ? (
      <Skeleton height="70%" width="70%" animation="wave" variant="rect" />
    ) :  */
    return (
      <VideoStyled
        src={src}
        alt={alt}
        customMaxHeight={customMaxHeight}
        customWidth={customWidth}
        controls
        controlsList="nodownload nofullscreen noremoteplayback"
      />
    );
  } else {
    return image.loading ? (
      <Skeleton height="70%" width="70%" animation="wave" variant="rect" />
    ) : (
      <ImgStyled
        id={`img-styled-${alt}`}
        src={src}
        onMouseOver={onMouseOver}
        alt={alt}
        customWidth={customWidth}
        customHeight={customHeight}
        customMaxHeight={customMaxHeight}
        objectFit={objectFit}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        margin={margin}
      />
    );
  }
}

export default ProgressiveImage;
