import React from "react";

import async from "../components/Async";

import { /*Monitor,*/ Users as UsersIcon, Box } from "react-feather";
import { Timeline } from "@material-ui/icons";

// Guards
import AuthGuard from "../components/guards/AuthGuard";
import AdminGuard from "../components/guards/AdminGuard";
import RedirectGuard from "../components/guards/RedirectGuard";
import SIGuard from "../components/guards/SIGuard";

// Managements
import Brands from "../pages/Management/Brands";
import Models from "../pages/Management/Models";
import Products from "../pages/Management/Products";
import Campaigns from "../pages/Management/Campaigns";
import Advertisers from "../pages/Management/Advertisers";
import Categories from "../pages/Management/Categories";
import Industries from "../pages/Management/Industries";
import Clients from "../pages/Management/Clients";
import Users from "../pages/Management/Users";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/errorPage/Page404"));
const Page500 = async(() => import("../pages/errorPage/Page500"));

// Dashboards components
// const Default = async(() => import("../pages/dashboards/Default"));

const Classify = async(() => import("../pages/Classify"));
const Classified = async(() => import("../pages/Classified"));
const ClientPage = async(() => import("../pages/Client"));
const ClientProductPage = async(() => import("../pages/ClientProduct"));
const CampaignPage = async(() => import("../pages/Campaigns"));
const SearchPage = async(() => import("../pages/SearchBrandProduct"));
const RequestDemo = async(() => import("../pages/RequestDemo"));
const ScraperPage = async(() => import("../pages/Scraper"));
const SocialPage = async(() => import("../pages/Social"));
const SocialHomePage = async(() => import("../pages/Social/Home"));
const SocialProjectPage = async(() => import("../pages/Social/Project"));
const AddSocialProfilePage = async(() =>
  import("../pages/Social/AddSocialProfile")
);

//Profile
const Settings = async(() => import("../pages/profile/Settings"));

// Landing
// const Landing = async(() => import("../pages/presentation/Landing"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <UsersIcon />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// const landingRoutes = {
//   id: "Landing Page",
//   path: "/",
//   header: "Docs",
//   icons: <Monitor />,
//   component: Landing,
//   children: null,
// };

const homeRoutes = {
  id: "Home Page",
  path: "/",
  header: "Display",
  icon: <Box />,
  component: Classify,
  children: null,
  guard: RedirectGuard,
  redirect: "classify",
  filters: true,
};

const classifyRoutes = {
  id: "Classify",
  path: "/classify",
  header: "Display",
  icon: <Box />,
  component: Classify,
  children: null,
  guard: AdminGuard,
};

const classifiedRoutes = {
  id: "Classified",
  path: "/classified",
  icon: <Box />,
  component: Classified,
  children: null,
  guard: AdminGuard,
};

const clientPageRoutes = {
  id: "Dashboard",
  path: "/client",
  icon: <Timeline />,
  component: ClientPage,
  children: null,
  guard: AuthGuard,
  client: true,
  options: {
    noSearch: true,
  },
};

const productClientPageRoutes = {
  id: "Product",
  path: "/productClient",
  icon: <Timeline />,
  component: ClientProductPage,
  children: null,
  guard: AuthGuard,
  client: true,
  options: {
    noSearch: true,
  },
};

const scraperPageRoutes = {
  id: "Scraper",
  path: "/scraper",
  icon: <Box />,
  component: ScraperPage,
  children: null,
  guard: AdminGuard,
  admin: true,
};

const socialHomePageRoutes = {
  id: "Home",
  path: "/social/home",
  header: "Social",
  icon: <Box />,
  component: SocialHomePage,
  guard: SIGuard,
  client: true,
  options: {
    noSearch: true,
    social: true,
  },
};

const socialProjectPageRoutes = {
  id: "Projects",
  icon: <Box />,
  path: "/social/projects/",
  component: SocialProjectPage,
  guard: SIGuard,
  client: true,
  options: {
    noSearch: true,
    social: true,
  },
};

const socialPageRoutes = {
  id: "Profiles",
  icon: <Box />,
  path: "/social/profile/",
  children: [],
  guard: SIGuard,
  client: true,
  options: {
    noSearch: true,
    social: true,
  },
};

const socialAddProfileRoutes = {
  id: "Add Social Profile",
  path: "/social/add-social-profile",
  header: "Add Social Profile",
  icon: <Box />,
  component: AddSocialProfilePage,
  children: null,
  guard: SIGuard,
  client: true,
  options: {
    noSearch: true,
    social: true,
  },
};

const socialProfileRoutes = {
  id: "Profile",
  path: "/social/profile/:name/:id/:type",
  icon: <Box />,
  component: SocialPage,
  children: null,
  guard: SIGuard,
  client: true,
  options: {
    noSearch: true,
    social: true,
  },
};

const campaignsPageRoutes = {
  id: "Campaigns",
  path: "/campaign/:id",
  icon: <Timeline />,
  component: CampaignPage,
  children: null,
  guard: AuthGuard,
  // client: true,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

//Profile Page
const ProfileRoutes = {
  id: "Profile",
  path: "/profile",
  component: Settings,
  children: null,
  guard: AuthGuard,
};

//Management Pages
const brandRoutes = {
  id: "Brand",
  path: "/management/brand",
  header: "Management",
  icon: <Box />,
  component: Brands,
  guard: AdminGuard,
  children: null,
  admin: true,
};

const modelRoutes = {
  id: "Model",
  path: "/management/model",
  icon: <Box />,
  component: Models,
  guard: AdminGuard,
  children: null,
  admin: true,
};

const productRoutes = {
  id: "Product",
  path: "/management/product",
  icon: <Box />,
  component: Products,
  guard: AdminGuard,
  children: null,
  admin: true,
};

const campaignRoutes = {
  id: "Campaign",
  path: "/management/campaign",
  icon: <Box />,
  component: Campaigns,
  guard: AdminGuard,
  children: null,
  admin: true,
};

const advertiserRoutes = {
  id: "Advertiser",
  path: "/management/advertiser",
  icon: <Box />,
  component: Advertisers,
  guard: AdminGuard,
  children: null,
  admin: true,
};

const categoryRoutes = {
  id: "Category",
  path: "/management/category",
  icon: <Box />,
  component: Categories,
  guard: AdminGuard,
  children: null,
  admin: true,
};

const industryRoutes = {
  id: "Industry",
  path: "/management/industry",
  icon: <Box />,
  component: Industries,
  guard: AdminGuard,
  children: null,
  admin: true,
};

const clientRoutes = {
  id: "Client",
  path: "/management/client",
  icon: <Box />,
  component: Clients,
  guard: AdminGuard,
  children: null,
  admin: true,
};

const userRoutes = {
  id: "User",
  path: "/management/user",
  icon: <Box />,
  component: Users,
  guard: AdminGuard,
  children: null,
  admin: true,
};

const searchRoutes = {
  id: "User",
  path: "/search",
  component: SearchPage,
  children: null,
};

const RequestDemoRoutes = {
  id: "User",
  path: "/requestDemo",
  component: RequestDemo,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [searchRoutes, RequestDemoRoutes];

// Routes that are protected
export const protectedRoutes = [
  protectedPageRoutes,
  homeRoutes,
  classifyRoutes,
  classifiedRoutes,
  ProfileRoutes,
  clientPageRoutes,
  productClientPageRoutes,
  scraperPageRoutes,
  socialHomePageRoutes,
  socialAddProfileRoutes,
  socialProfileRoutes,
  socialProjectPageRoutes,
  socialPageRoutes,
  campaignsPageRoutes,
  brandRoutes,
  modelRoutes,
  productRoutes,
  campaignRoutes,
  advertiserRoutes,
  categoryRoutes,
  industryRoutes,
  clientRoutes,
  userRoutes,
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  classifyRoutes,
  classifiedRoutes,
  clientPageRoutes,
  productClientPageRoutes,
  scraperPageRoutes,
  socialHomePageRoutes,
  socialProjectPageRoutes,
  socialPageRoutes,
  brandRoutes,
  modelRoutes,
  productRoutes,
  campaignRoutes,
  advertiserRoutes,
  categoryRoutes,
  industryRoutes,
  clientRoutes,
  userRoutes,
];
