import React from "react";
import styled, { withTheme } from "styled-components/macro";
import { darken, lighten } from "polished";
import { Search as SearchIcon } from "react-feather";

import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Button as MuiButton,
  Toolbar,
} from "@material-ui/core";

import {
  Menu as MenuIcon,
  AddCircleOutline as AddIcon,
} from "@material-ui/icons";

// import NotificationsDropdown from "./NotificationsDropdown";
// import MessagesDropdown from "./MessagesDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalFilters } from "../redux/actions/filtersActions";
import { Link } from "react-router-dom";

const Button = styled(MuiButton)`
  color: #fff;
  background-color: #000;
  &:hover {
    color: #fff;
    background-color: ${(props) => lighten(0.2, "#000")};
  }
`;

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    // width: 260px;
  }
`;

const AppBarComponent = ({ onDrawerToggle, options }) => {
  const dispatch = useDispatch();
  const search = useSelector((state) => state.filtersReducer.search);

  function onSearch(event) {
    const { value } = event.target;
    dispatch(setGlobalFilters(value));
  }
  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden lgDown>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            {!options?.noSearch ? (
              <Grid item xs={3}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <Input
                    placeholder="Search topics"
                    onChange={onSearch}
                    value={search}
                  />
                </Search>
              </Grid>
            ) : null}
            {options?.social ? (
              <Grid item xs={3}>
                <Button
                  aria-label="Open drawer"
                  variant="contained"
                  component={Link}
                  to="/social/add-social-profile"
                  // onClick={onDrawerToggle}
                >
                  <AddIcon style={{ marginRight: "10px" }} /> Add Social Profile
                </Button>
              </Grid>
            ) : null}
            <Grid item xs />
            <Grid item>
              {/*<MessagesDropdown />*/}
              {/*<NotificationsDropdown />*/}
              <LanguagesDropdown />
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AppBarComponent);
