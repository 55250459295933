import * as types from "../../constants";

let user = localStorage.getItem("user");
user = user && JSON.parse(user);

const initialState = {
  user: user
    ? {
        id: user.id,
        username: user.username,
        name: user.get_full_name,
        email: user.email,
        groups: user.groups,
        si_enabled: user?.client?.si_enabled,
      }
    : null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: {
          id: actions.id,
          email: actions.email,
          name: actions.name,
          groups: actions.groups,
          si_enabled: actions.si_enabled,
        },
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: undefined,
      };

    default:
      return state;
  }
}
