import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import Sidebar from "../components/Sidebar";
import Header from "../components/AppBar";
import Footer from "../components/Footer";
import { makeStyles } from "@material-ui/core/styles";
// import Settings from "../components/Settings";

import { spacing } from "@material-ui/system";
import { CssBaseline, Paper as MuiPaper, withWidth } from "@material-ui/core";

// import { isWidthUp } from "@material-ui/core/withWidth";

const drawerWidth = 265;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

// const Drawer = styled.div`
//   ${(props) => props.theme.breakpoints.up("md")} {
//     width: ${drawerWidth}px;
//     flex-shrink: 0;
//   }
// `;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.open ? "0" : "-265px")};
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  socialIcon: {
    width: "22px !important",
    height: "22px !important",
  },
}));

const Dashboard = ({ children, routes, options }) => {
  const [mobileOpen, setMobileOpen] = useState(true);
  const classes = useStyles();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {/*<Drawer open={mobileOpen}>*/}
      {/*<Hidden smDown implementation="js">*/}
      <Sidebar
        routes={routes}
        PaperProps={{ style: { width: drawerWidth } }}
        variant="persistent"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={classes}
      />
      {/*</Hidden>*/}
      {/*<Hidden smDown implementation="css">*/}
      {/*  <Sidebar*/}
      {/*    routes={routes}*/}
      {/*    PaperProps={{ style: { width: drawerWidth } }}*/}
      {/*  />*/}
      {/*</Hidden>*/}
      {/*</Drawer>*/}
      <AppContent open={mobileOpen}>
        <Header onDrawerToggle={handleDrawerToggle} options={options} />
        <MainContent>{children}</MainContent>
        <Footer />
      </AppContent>
      {/*<Settings />*/}
    </Root>
  );
};

export default withWidth()(Dashboard);
