import React, { Component } from "react";
import Page500 from "../pages/errorPage/Page500";
import { withRouter } from "react-router-dom";
import AuthLayout from "../layouts/Auth";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    location: null,
  };
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  static getDerivedStateFromProps(newProps, oldState) {
    const navigated = newProps.location !== oldState.location;
    if (navigated && oldState.hasError) {
      return {
        hasError: false,
        location: newProps.location,
      };
    } else {
      return {
        location: newProps.location,
      };
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <AuthLayout>
          <Page500 status={500} />
        </AuthLayout>
      );
    } else {
      return this.props.children;
    }
  }
}

export default withRouter(ErrorBoundary);
