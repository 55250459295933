import * as types from "../../constants";

export function reportRefetch() {
  return {
    type: types.REPORT_REFETCH,
    refetch: true,
  };
}

export function reportClean() {
  return {
    type: types.REPORT_CLEAN,
    refetch: false,
  };
}
