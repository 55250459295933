import React from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { clearSnackNotification } from "../redux/actions/snackAction";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SnackNotification() {
  const dispatch = useDispatch();

  const snackNotify = useSelector((state) => state.snackReducer);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(clearSnackNotification());
  };

  return (
    <Snackbar
      open={snackNotify.open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={snackNotify.severity}>
        {snackNotify.msg}
      </Alert>
    </Snackbar>
  );
}

export default SnackNotification;
