import React from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import { CircularProgress, TablePagination } from "@material-ui/core";
import TablePaginationActions from "./TablePaginationAction";

const Layout = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : "unset"};
  grid-gap: 10px;
  grid-auto-rows: 2px;
  box-sizing: border-box;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function MasonryLayout({
  columns,
  children,
  infiniteScroll,
  loadFunc,
  pageStart,
  hasMore,
  count,
  page,
  loadData,
  rowsPerPage,
  setRowsPerPage,
  withPagination,
}) {
  function renderLayout() {
    return <Layout columns={columns.length}>{children}</Layout>;
  }
  const handleChangePage = (event, newPage) => {
    loadData(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return infiniteScroll ? (
    <InfiniteScroll
      pageStart={pageStart}
      loadMore={loadFunc}
      hasMore={hasMore}
      loader={
        <LoadingContainer className="loader" key={0}>
          <CircularProgress />
        </LoadingContainer>
      }
    >
      {renderLayout()}
    </InfiniteScroll>
  ) : withPagination ? (
    <>
      {renderLayout()}
      <TablePagination
        rowsPerPageOptions={[]}
        count={count || 0}
        rowsPerPage={rowsPerPage || 100}
        page={page - 1}
        component="div"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </>
  ) : (
    renderLayout()
  );
}

export default MasonryLayout;
