import {
  Checkbox,
  // IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  // Tooltip,
  Typography,
} from "@material-ui/core";
// import {
//   Archive as ArchiveIcon,
//   FilterList as FilterListIcon,
// } from "@material-ui/icons";
import React from "react";
import styled from "styled-components/macro";

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  checkboxEnable,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {checkboxEnable ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all" }}
            />
          </TableCell>
        ) : null}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            width={headCell.width}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function EnhancedTableToolbar({ numSelected, name }) {
  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {name}
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {/*{numSelected > 0 ? (*/}
        {/*  <Tooltip title="Delete">*/}
        {/*    <IconButton aria-label="Delete">*/}
        {/*      <ArchiveIcon />*/}
        {/*    </IconButton>*/}
        {/*  </Tooltip>*/}
        {/*) : (*/}
        {/*  <Tooltip title="Filter list">*/}
        {/*    <IconButton aria-label="Filter list">*/}
        {/*      <FilterListIcon />*/}
        {/*    </IconButton>*/}
        {/*  </Tooltip>*/}
        {/*)}*/}
      </div>
    </Toolbar>
  );
}
