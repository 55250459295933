import React, { useState } from "react";
import ProgressiveImageSize from "../../components/ProgressiveImageSize";
import { Popover } from "@material-ui/core";
import CardPopupClassified from "./CardPopupClassified";
import * as ClassifyService from "../../services/classifyService";
import { setSnackNotification } from "../../redux/actions/snackAction";
import BannerDetails from "../Classified/BannerDetails";
import ConfirmDialog from "../../components/ConfirmDialog";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import getProjectVariant from "../../utils/imageVariant";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
    backgroundColor: "unset",
  },
}));

function BannerPopover({ banner, refetchBanners }) {
  const classes = useStyles();
  const [openedPopover, setOpenedPopover] = useState({
    openedId: null,
    anchorEl: null,
  });
  const [openDetails, setOpenDetails] = useState({ open: false, banners: [] });
  const [confirmOpen, setConfirmOpen] = useState({ open: false, banners: [] });
  const dispatch = useDispatch();

  const handlePopoverOpen = (id, event) => {
    setOpenedPopover({ openedId: id, anchorEl: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setOpenedPopover({ openedId: null, anchorEl: null });
  };

  function handleClose() {
    setConfirmOpen({ open: false, banners: [] });
  }

  async function unClassifyBanner() {
    const { banners } = confirmOpen;
    const bannersList = JSON.stringify(banners)
      .substr(1, JSON.stringify(banners).length - 2)
      .replaceAll('"', "");
    handleClose();
    if (banners.length)
      ClassifyService.addClassify(`/banners/?id__in=${bannersList}`, {
        status: "review",
        campaign: null,
        product: null,
        model: null,
      })
        .then(() => {
          dispatch(
            setSnackNotification("Operation successfully completed.", "success")
          );
          refetchBanners();
        })
        .catch((error) => {
          dispatch(
            setSnackNotification(
              "An error has occurred, please try again",
              "error"
            )
          );
          console.log("error", error);
        });
  }

  return (
    <>
      <ProgressiveImageSize
        key={banner.id}
        src={banner.type.indexOf("video") !== -1 ? banner.thumb : banner.media}
        alt={banner.id}
        size={banner.size}
        type={banner.type}
        aria-owns={
          openedPopover.openedId === banner.id
            ? "mouse-over-popover"
            : undefined
        }
        aria-haspopup="true"
        onClick={(evt) => handlePopoverOpen(banner.id, evt)}
      />
      <Popover
        key={`pop-${banner.id}`}
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openedPopover.openedId === banner.id}
        anchorEl={openedPopover.anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        PaperProps={{
          onMouseLeave: handlePopoverClose,
        }}
      >
        <CardPopupClassified
          banner={banner}
          setConfirmOpen={setConfirmOpen}
          variant={getProjectVariant(banner.size)}
          setOpenDetails={setOpenDetails}
        />
      </Popover>
      {openDetails.open ? (
        <BannerDetails
          open={openDetails.open}
          setOpen={setOpenDetails}
          banners={openDetails.banners}
          loadBanners={refetchBanners}
          countryFilter={"all"}
        />
      ) : null}
      <ConfirmDialog
        title="Unclassify Banner?"
        open={confirmOpen.open}
        handleClose={handleClose}
        onConfirm={unClassifyBanner}
      >
        {`Are you sure you want to unclassify ${
          confirmOpen.banners.length > 1 ? "these" : "this"
        } banner?`}
      </ConfirmDialog>
    </>
  );
}

export default BannerPopover;
