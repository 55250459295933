const getResolution = {
  Small: () => (window.document.body.clientWidth <= 480 ? 1 : null),
  Medium: () => (window.document.body.clientWidth <= 768 ? 2 : null),
  Large: () => (window.document.body.clientWidth <= 1200 ? 4 : null),
  xLarge: () => (window.document.body.clientWidth <= 1450 ? 5 : null),
  Giant: () => (window.document.body.clientWidth < 1920 ? 6 : null),
  Big: () => (window.document.body.clientWidth >= 1920 ? 6 : null),
  any() {
    return (
      getResolution.Small() ||
      getResolution.Medium() ||
      getResolution.Large() ||
      getResolution.xLarge() ||
      getResolution.Giant() ||
      getResolution.Big()
    );
  },
};

const resolution = () => getResolution.any();
export default resolution;
