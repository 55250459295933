import * as types from "../../constants";

const initialState = {
  refetch: false,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.REPORT_REFETCH:
      return {
        ...state,
        ...actions,
      };

    case types.REPORT_CLEAN:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
