import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Tabs,
  Tab,
  AppBar,
  Button as MuiButton,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { CloseOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import DetailsTable from "../common/DetailsTable";
import * as ClassifyService from "../../services/classifyService";
import { setSnackNotification } from "../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import ProgressiveImage from "../../components/ProgressiveImage";
import DisplayDetailsTable from "../common/DisplayDetailsTable";
import useAxios from "axios-hooks";
import AssignCampaign from "../common/AssignCampaign";
import NewCampaign from "../Management/components/NewCampaign";

const Grid = styled(MuiGrid)(spacing);

const MuiPaperStyled = styled(MuiPaper)(spacing);

const Paper = styled(MuiPaperStyled)`
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled(MuiButton)`
  border-radius: unset;
`;

const useStyles = makeStyles(() => ({
  paper: {
    height: "90%",
  },
  paperFlex: {
    display: "flex",
    flexDirection: "column",
  },
  tabs: {
    flexGrow: 1,
    width: "100%",
    flexDirection: "column",
  },
  tabPanel: {
    width: "100%",
    // height: "500px",
    padding: "50px",
  },
  paperDetail: {
    height: "100%",
    padding: "10px",
    // display: "flex",
  },
  paperFull: {
    height: "100%",
    display: "flex",
    padding: "10px",
    flexDirection: "column",
  },
  typography: {
    alignSelf: "flex-end",
    margin: "30px 20px 20px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function BannerDetails({ open, setOpen, banners, loadBanners, countryFilter }) {
  const classes = useStyles();
  const [data, setData] = useState({
    campaignSelect: null,
    productSelect: null,
    brandSelect: null,
    modelSelect: null,
    category: null,
    data: null,
  });
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState(1);
  const [openCampaigns, setOpenCampaigns] = useState({
    open: false,
    data: null,
  });

  const [filters, setFilters] = React.useState({
    product: "",
    campaign: "",
    model: "",
  });
  const [
    { data: display, loading, error: errorDisplay },
    refetchDisplay,
  ] = useAxios({
    url: "/displays/",
    params: {
      page: pagination,
      page_size: 10,
      banner: banners ? banners[0] : undefined,
    },
  });

  const [
    { data: bannerDetails, error: errorBanners },
    refetchBanners,
  ] = useAxios({
    url: `/banners/?id__in=${banners.toString()}`,
  });

  const [{ data: campaignData }] = useAxios(
    { url: `/campaigns/${data.campaignSelect}` },
    { manual: !data.campaignSelect }
  );

  const [{ data: countries }] = useAxios({
    url: `/countries/`,
  });

  const [{ data: banner }] = useAxios(
    {
      url: `/banners/${banners[0]}`,
    },
    { manual: banners?.length !== 1 }
  );

  useEffect(() => {
    if (errorDisplay || errorBanners) {
      dispatch(
        setSnackNotification("An error has occurred, please try again", "error")
      );
      console.log("error", errorDisplay || errorBanners);
    }
  }, [errorDisplay, errorBanners, dispatch]);

  useEffect(() => {
    if (bannerDetails?.results?.length === 1) {
      setData((prevState) => ({
        ...prevState,
        data: bannerDetails.results[0],
        campaignSelect: bannerDetails.results[0].campaign?.id,
        productSelect: bannerDetails.results[0].product?.id,
        modelSelect: bannerDetails.results[0].model?.id,
      }));
      setFilters({
        product: bannerDetails.results[0].product?.name,
        campaign: bannerDetails.results[0]?.campaign?.name,
        model: bannerDetails.results[0]?.model?.name,
      });
    }
  }, [bannerDetails]);

  useEffect(() => {
    if (campaignData) {
      setData((prevState) => ({
        ...prevState,
        category: campaignData.category.id,
      }));
    }
  }, [campaignData]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  async function onClassify() {
    if (banners.length)
      ClassifyService.addClassify(`/banners/?id__in=${banners.toString()}`, {
        status: "active",
        campaign: data.campaignSelect,
        product: data.productSelect,
        model: data.modelSelect,
        date_review: new Date(),
      })
        .then(() => {
          dispatch(
            setSnackNotification("Operation successfully completed.", "success")
          );
          onClose();
          refetchBanners();
          loadBanners();
        })
        .catch((error) => {
          dispatch(
            setSnackNotification(
              "An error has occurred, please try again",
              "error"
            )
          );
          console.log("error", error);
        });
  }

  function onClose() {
    setData({
      campaignSelect: null,
      productSelect: null,
      data: null,
    });
    setOpen({ open: false, banners: [] });
  }

  // async function onEditCampaign() {
  //   const { data: campaignData } = await execute({
  //     url: `/campaigns/${data.campaignSelect}`,
  //   });
  //   setOpenCampaigns({ open: true, data: campaignData });
  // }

  function setClose(reload) {
    if (reload) refetchDisplay();
    setOpenCampaigns({ open: false, data: null });
  }

  function renderEdit() {
    return (
      <>
        <AssignCampaign
          data={data}
          setData={setData}
          horizontal
          invertPopoverPos
          filters={filters}
          setFilters={setFilters}
          countryFilter={countryFilter}
        />
        <Grid container spacing={3} mt={10}>
          <Grid item xs={6}>
            <Button
              onClick={onClose}
              color="secondary"
              variant="contained"
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={onClassify}
              color="primary"
              variant="contained"
              disabled={!(data.campaignSelect && data.productSelect)}
              fullWidth
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth={true}
      maxWidth={"xl"}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle id="form-dialog-title">
        Classified Detail
        <CloseOutlined
          style={{ float: "right", cursor: "pointer" }}
          onClick={() => setOpen({ open: false, banner: null })}
        />
      </DialogTitle>
      <DialogContent>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {!loading && banners?.length === 1 ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Paper>
                  {/*<ProgressiveImage*/}
                  {/*  src={banner?.media}*/}
                  {/*  alt={banner?.id}*/}
                  {/*  type={banner?.type?.split("/")[0]}*/}
                  {/*/>*/}
                  <ProgressiveImage
                    src={data.data?.media}
                    alt={data.data?.id}
                    type={data.data?.type?.split("/")[0]}
                  />
                </Paper>
              </Grid>
              <Grid item xs={8}>
                <Paper className={classes.paperFull}>
                  <DetailsTable
                    data={data?.data}
                    campaign={campaignData}
                    // onEditCampaign={onEditCampaign}
                    // regions={display?.results?.map((d) => d.region)}
                    regions={countries?.results?.filter((c) =>
                      banner?.countries?.some((b) => b === c.id)
                    )}
                  />
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography
                        component="div"
                        className={classes.typography}
                      >
                        <Box fontWeight="fontWeightBold" m={1}>
                          {data.data && `First Seen: ${data.data.date_start}`}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        component="div"
                        className={classes.typography}
                      >
                        <Box fontWeight="fontWeightBold" m={1}>
                          {data.data && `ID: ${data.data.id}`}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                  {openCampaigns.open ? (
                    <NewCampaign
                      open={openCampaigns.open}
                      setClose={setClose}
                      editData={openCampaigns.data}
                    />
                  ) : null}
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid xs={12} item>
                <Paper className={classes.tabs}>
                  <AppBar position="static" color="transparent">
                    <Tabs
                      value={tabValue}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleChangeTab}
                      // aria-label="disabled tabs example"
                    >
                      <Tab color="default" label="Edit" {...a11yProps(0)} />
                      <Tab label="Display" {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={tabValue} index={0}>
                    {renderEdit()}
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <MuiPaperStyled className={classes.paperDetail}>
                      <DisplayDetailsTable
                        rows={display?.results}
                        page={pagination}
                        loadData={(p) => setPagination(p)}
                        showPagination
                        count={display?.count}
                      />
                    </MuiPaperStyled>
                  </TabPanel>
                </Paper>
              </Grid>
            </Grid>
          </>
        ) : null}
        {!loading && banners?.length > 1 ? renderEdit() : null}
      </DialogContent>
    </Dialog>
  );
}

export default BannerDetails;
