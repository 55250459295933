import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import filtersReducer from "./filtersReducer";
import snackReducer from "./snackReducer";
import reportReducer from "./reportReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  filtersReducer,
  snackReducer,
  reportReducer,
});
