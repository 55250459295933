import * as types from "../../constants";

export function setGlobalFilters(search) {
  return {
    type: types.FILTERS_SET,
    search,
  };
}

export function cleanFilters() {
  return {
    type: types.FILTERS_CLEAN,
    search: "",
  };
}
