import React from "react";
import {
  Delete as DeleteIcon,
  Visibility as ViewIcon,
} from "@material-ui/icons";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardActions as MuiCardActions,
  CardContent,
  IconButton as MuiIconButton,
  Typography,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";

const Card = styled(MuiCard)`
  position: relative;
  background-color: unset;
`;

const CardActions = styled(MuiCardActions)`
  // background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  padding: unset;
  > :not(:first-child) {
    margin-left: 0px;
  }
`;

const CardActionsHeader = styled(MuiCardActions)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  padding: unset;
  position: ${(props) => (props.variant === "large" ? "absolute" : "relative")};
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: ${(props) =>
    props.variant === "large" ? "transparent" : "white"};
`;

const IconButtonCustom = styled(MuiIconButton)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) =>
    props.theme.palette[props.color || "primary"].main};
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.contrastText};
    color: ${(props) => props.theme.palette[props.color || "primary"].main};
  }
`;

const IconButton = styled(IconButtonCustom)(spacing);
const Button = styled(MuiButton)(spacing);
const ButtonSquare = styled(Button)`
  border-radius: unset;
`;

const ProjectImgStyled = styled.img`
  display: block;
  width: auto;
  height: 100%;
  max-height: calc(100vh - 74px);
  object-fit: cover;
  background-color: white;
`;

const VidStyled = styled.video`
  display: block;
  width: auto;
  height: auto;
  max-height: calc(100vh - 74px);
  object-fit: cover;
`;

const useStyles = makeStyles((theme) => ({
  elipseText: {
    overflow: "hidden",
    whiteSpace: "normal",
    maxWidth: 450,
    overflowWrap: "anywhere",
  },
}));

function CardPopupClassified({
  banner,
  setClassifyOpen,
  setOpenCompare,
  setBannersSelected,
  setConfirmOpen,
  variant,
  setOpenDetails,
  showActions,
}) {
  const classes = useStyles();
  let mediaType = banner.type.split("/")[0];

  return (
    <Card>
      <CardActionsHeader variant={variant}>
        {setOpenDetails ? (
          <IconButton
            m={2}
            size="small"
            aria-label="View"
            color="secondary"
            onClick={() => setOpenDetails({ open: true, banners: [banner.id] })}
          >
            <ViewIcon color="inherit" />
          </IconButton>
        ) : null}
        {setConfirmOpen ? (
          <IconButton
            m={2}
            size="small"
            aria-label="Delete"
            onClick={() => setConfirmOpen({ open: true, banners: [banner.id] })}
          >
            <DeleteIcon color="inherit" />
          </IconButton>
        ) : null}
      </CardActionsHeader>
      <CardContent style={{ padding: 0 }}>
        <Box
          width={
            variant === "medium"
              ? parseInt(banner?.size?.split("x")?.[0]) || "auto"
              : "auto"
          }
          maxWidth="80vw"
          display="flex"
          flexDirection={variant === "large" ? "row" : "column"}
        >
          {mediaType === "video" ? (
            // <ProjVidContainer>
            <VidStyled
              src={`${banner.media}`}
              controls
              controlsList="nodownload nofullscreen noremoteplayback"
            />
          ) : (
            <ProjectImgStyled src={banner.media} alt={banner.id} />
          )}
          <Box
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            display="flex"
            flexDirection={
              ["medium", "large"].includes(variant) ? "column" : "row"
            }
            bgcolor="white"
            padding={5}
            minWidth={250}
          >
            <Box
              paddingRight={variant === "small" ? 5 : 0}
              marginTop={variant === "large" ? 5 : 0}
            >
              <Typography variant="subtitle2">Brand/Model:</Typography>
              <Typography className={classes.elipseText}>
                {`${banner?.campaign?.brand?.name || ""}${
                  banner?.campaign?.model
                    ? `/${banner?.campaign?.model?.name}`
                    : ""
                }`}
              </Typography>
            </Box>
            <Box
              paddingTop={variant === "small" ? 0 : 5}
              paddingRight={variant === "small" ? 5 : 0}
            >
              <Typography variant="subtitle2">Product:</Typography>
              <Typography className={classes.elipseText}>
                {banner.product?.name}
              </Typography>
            </Box>
            <Box
              paddingTop={variant === "small" ? 0 : 5}
              paddingRight={variant === "small" ? 5 : 0}
            >
              <Typography variant="subtitle2">Campaign:</Typography>
              <Typography className={classes.elipseText}>
                {banner.campaign?.name}
              </Typography>
            </Box>
            <Box
              paddingTop={variant === "small" ? 0 : 5}
              paddingRight={variant === "small" ? 5 : 0}
            >
              <Typography variant="subtitle2">Category:</Typography>
              <Typography className={classes.elipseText}>
                {banner.campaign?.category?.name}
              </Typography>
            </Box>
            <Box
              paddingTop={variant === "small" ? 0 : 5}
              paddingRight={variant === "small" ? 5 : 0}
              marginTop={variant === "small" ? 0 : "auto"}
              marginLeft={variant === "small" ? "auto" : 0}
            >
              <Typography variant="subtitle2">First Seen:</Typography>
              <Typography className={classes.elipseText}>
                {banner.date_start}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
      {showActions ? (
        <CardActions>
          <ButtonSquare
            size="large"
            color="secondary"
            variant="contained"
            onClick={() => setOpenCompare({ open: true, banner: banner.id })}
            fullWidth
          >
            Compare
          </ButtonSquare>
          <ButtonSquare
            size="large"
            color="primary"
            variant="contained"
            onClick={() => {
              setClassifyOpen({ open: true, banners: [banner.id] });
              setBannersSelected([banner.id]);
            }}
            fullWidth
          >
            Classify
          </ButtonSquare>
        </CardActions>
      ) : null}
    </Card>
  );
}

export default CardPopupClassified;
