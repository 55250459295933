import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { DropzoneArea } from "material-ui-dropzone";
import { CloseOutlined } from "@material-ui/icons";
import * as ClassifyService from "../../../services/classifyService";
import { setSnackNotification } from "../../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import capitalize from "../../../utils/capitalize";

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  min-width: -webkit-fill-available;
`;

function NewBrand({ open, setClose, editData }) {
  const [data, setData] = useState({
    name: "",
    logo: null,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (editData) setData({ name: editData.name, logo: editData.logo });
  }, [editData]);

  function handleChange(evt) {
    const { id, value } = evt.target;
    setData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  }

  function handleChangeImage(files) {
    setData((prevState) => ({
      ...prevState,
      logo: files[0],
    }));
  }

  function onClose(reload) {
    setData({
      name: "",
      logo: null,
    });
    setClose(reload);
  }

  function handleAdd() {
    const { id } = editData || {};
    const postData = { ...data, name: capitalize(data.name) };
    ClassifyService.postWithFormData(`/brands/${id || ""}`, postData, !!id)
      .then(() => {
        dispatch(
          setSnackNotification("Operation successfully completed.", "success")
        );
        onClose(true);
      })
      .catch((error) => {
        dispatch(
          setSnackNotification(
            "An error has occurred, please try again",
            "error"
          )
        );
        console.log("error", error);
      });
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={false}>
        <DialogTitle id="form-dialog-title">
          {editData ? "Edit Brand" : "New Brand"}
          <CloseOutlined
            style={{ float: "right", cursor: "pointer" }}
            onClick={onClose}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                m={2}
                required
                id="name"
                label="Name"
                value={data.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <DropzoneArea
                filesLimit={1}
                showFileNamesInPreview={true}
                initialFiles={[data.logo]}
                showFileNames={true}
                acceptedFiles={["image/*"]}
                onChange={handleChangeImage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            onClick={handleAdd}
            color="primary"
            variant="contained"
            fullWidth
            disabled={!data.name}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewBrand;
