import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow,
  TablePagination,
  IconButton,
  TableHead,
  Button,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import styled from "styled-components/macro";
import {
  DesktopWindows as DesktopWindowsIcon,
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
  Smartphone as SmartphoneIcon,
  Input as InputIcon,
} from "@material-ui/icons";
import moment from "moment";

const TableCell = styled(MuiTableCell)`
  border: none;
`;

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  hover: {
    cursor: "pointer",
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function DisplayDetailsTable({
  rows,
  name,
  showPagination,
  page,
  loadData,
  count,
}) {
  const handleChangePage = (event, newPage) => {
    loadData(newPage + 1);
  };

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">Region</TableCell>
            <TableCell align="center">Domain</TableCell>
            <TableCell align="center">Url Show</TableCell>
            <TableCell align="center">Size</TableCell>
            <TableCell align="center">Browser Type</TableCell>
            <TableCell align="center">Page Deep</TableCell>
            <TableCell align="center">Date Created</TableCell>
            <TableCell align="center">ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" align="center">
                  {row.region?.name || ""}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.website?.name || ""}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  <Button
                    variant="link"
                    // color="inherit"
                    target="_blank"
                    startIcon={<InputIcon />}
                    href={row.page_url}
                  />
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.size}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.browser_type?.slug === "desktop" ? (
                    <Tooltip title="Desktop">
                      <DesktopWindowsIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Mobile">
                      <SmartphoneIcon />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.page_depth}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {moment(row.date_created).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.id}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {showPagination ? (
        <TablePagination
          rowsPerPageOptions={[]}
          colSpan={3}
          count={count || 0}
          rowsPerPage={15}
          page={page - 1}
          component="div"
          // SelectProps={{
          //   inputProps: { "aria-label": "rows per page" },
          //   native: true,
          // }}
          onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      ) : null}
    </>
  );
}

export default DisplayDetailsTable;
