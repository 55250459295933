import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  dashboardLayoutRoutes,
  authLayoutRoutes,
  presentationLayoutRoutes,
  protectedRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import PresentationLayout from "../layouts/Presentation";
import Page404 from "../pages/errorPage/Page404";
import ErrorBoundary from "../components/ErrorBoundary";

const NoGuard = ({ children }) => children;

const childRoutes = (Layout, routes) =>
  routes.map(
    (
      { component: Component, guard, children, path, redirect, options },
      index
    ) => {
      const Guard = guard || NoGuard;
      return children ? (
        children.map((element, index) => {
          const Guard = element.guard || React.Fragment;

          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props) => (
                <Guard>
                  <ErrorBoundary>
                    <Layout options={options}>
                      <element.component {...props} />
                    </Layout>
                  </ErrorBoundary>
                </Guard>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Guard redirect={redirect}>
              <ErrorBoundary>
                <Layout options={options}>
                  <Component {...props} />
                </Layout>
              </ErrorBoundary>
            </Guard>
          )}
        />
      ) : null;
    }
  );

const Routes = () => (
  <Router>
    <Switch>
      {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
      {childRoutes(DashboardLayout, protectedRoutes)}
      {childRoutes(AuthLayout, authLayoutRoutes)}
      {childRoutes(PresentationLayout, presentationLayoutRoutes)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default Routes;
