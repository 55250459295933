import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  InputAdornment,
  Popover,
  TextField as MuiTextField,
  Tooltip,
  Paper,
} from "@material-ui/core";
import { Loop, Sort } from "@material-ui/icons";
import EnhancedTable from "../Classify/components/EnhancedTable";
import { setSnackNotification } from "../../redux/actions/snackAction";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import ProgressiveImageSize from "../../components/ProgressiveImageSize";
import Masonry from "../../components/Masonry";
import useAxios from "axios-hooks";
import SearchCampaign from "./SearchCampaign";

const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  width: 100%;
`;

const SortIcon = styled(Sort)(spacing);
const LoopIcon = styled(Loop)(spacing);

const useStyles = makeStyles(() => ({
  divider: {
    height: 12,
    margin: 5,
  },
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
    backgroundColor: "unset",
  },
  paper: {
    zIndex: 100,
    width: "600px",
    height: "100%",
    maxHeight: "700px",
    padding: "20px",
    border: "2px solid black",
    overflow: "scroll",
  },
}));

function AssignCampaign({
  data,
  setData,
  horizontal,
  invertPopoverPos,
  filters,
  setFilters,
  countryFilter,
}) {
  const classes = useStyles();
  const [openSearchCampaigns, setOpenSearchCampaigns] = useState(false);
  const dispatch = useDispatch();
  const [campaignPreview, setCampaignPreview] = useState([]);
  const [openedPopover, setOpenedPopover] = useState({
    openedId: null,
    anchorEl: null,
  });
  const [, execute, cancelRequest] = useAxios({}, { manual: true });
  const [pagination, setPagination] = useState({
    campaign: 1,
    product: 1,
    model: 1,
  });
  const [{ data: models, error: errorModels }, refetchModels] = useAxios({
    url: "/models/",
    params: {
      page: pagination.model,
      page_size: 10,
      search: filters.model,
      brand: data.brandSelect,
      product: data.productSelect,
    },
  });
  const [productsBy, setProductsBy] = useState("brand");
  const configProduct =
    productsBy === "brand"
      ? {
          url: `/brands/${data.brandSelect}/products`,
          params: {
            page: pagination.product,
            page_size: 10,
            // search: filters.product,
          },
        }
      : {
          url: "/products/",
          params: {
            page: pagination.product,
            page_size: 10,
            search: filters.product,
            category__in: data.category,
          },
        };
  const [{ data: products, error: errorProduct }] = useAxios(configProduct, {
    manual: productsBy === "brand" && !data.brandSelect,
  });
  const [
    { data: campaigns, error: errorCampaign },
    refetchCampaigns,
  ] = useAxios({
    url: "/campaigns/",
    params: {
      page: pagination.campaign,
      page_size: 15,
      search: filters.campaign,
      in_country: countryFilter === "all" ? "" : countryFilter,
    },
  });

  useEffect(() => {
    if (data.campaignSelect && campaigns?.results && !data.brandSelect) {
      const camp = campaigns.results.find((x) => x.id === data.campaignSelect);
      camp?.brand?.id &&
        setData((prevState) => ({
          ...prevState,
          brandSelect: camp.brand.id,
        }));
      // if (camp.brand) setProductsBy("brand");
    }
  }, [data.campaignSelect, data.brandSelect, campaigns, setData]);

  useEffect(() => {
    if (errorProduct || errorCampaign || errorModels) {
      dispatch(
        setSnackNotification("An error has occurred, please try again", "error")
      );
      console.log("error", errorProduct || errorCampaign || errorModels);
    }
  }, [errorProduct, errorCampaign, errorModels, dispatch]);

  function handleChange(evt) {
    const { id, value } = evt.target;
    setFilters((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setPagination((prevState) => ({ ...prevState, [id]: 1 }));
  }

  // function handleAddProduct(data) {
  //   ClassifyService.postData("/products/", data)
  //     .then(() => {
  //       dispatch(
  //         setSnackNotification("Operation successfully completed.", "success")
  //       );
  //       refetchProducts({
  //         url: "/products/",
  //         params: {
  //           page: pagination.product,
  //           page_size: 15,
  //           search: filters.product,
  //         },
  //       });
  //     })
  //     .catch((error) => {
  //       dispatch(
  //         setSnackNotification(
  //           "An error has occurred, please try again",
  //           "error"
  //         )
  //       );
  //       console.log("error", error);
  //     });
  // }

  // function handleSearch(productId, productName) {
  //   setData((prevState) => ({
  //     ...prevState,
  //     productSelect: productId,
  //   }));
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     product: productName,
  //   }));
  // }

  function handleSearchCampaign(camp) {
    setData((prevState) => ({
      ...prevState,
      campaignSelect: camp.id,
      category: camp.category.id,
      brandSelect: camp.brand.id,
      productSelect: null,
    }));
    setFilters((prevState) => ({
      ...prevState,
      campaign: camp.name,
    }));
    if (camp.brand) setProductsBy("brand");
  }

  function handleClickCampaign(key, value) {
    const category = campaigns.results.find((x) => x.id === value).category.id;
    const brand = campaigns.results.find((x) => x.id === value).brand.id;
    if (data.campaignSelect === value)
      setFilters((prevState) => ({
        ...prevState,
        product: "",
      }));
    setData((prevState) => ({
      ...prevState,
      campaignSelect: prevState.campaignSelect === value ? null : value,
      productSelect: null,
      category: prevState.campaignSelect === value ? null : category,
      brandSelect: prevState.campaignSelect === value ? null : brand,
    }));
    if (brand) setProductsBy("brand");
  }

  function handleClick(key, value) {
    setData((prevState) => ({
      ...prevState,
      [key]: prevState[key] === value ? null : value,
    }));
  }

  function handlePopoverClose() {
    cancelRequest();
    setOpenedPopover({ openedId: null, anchorEl: null });
  }

  async function handlePopoverOpen(id, event) {
    const { currentTarget } = event;
    const { data: bannersPreview } = await execute({
      url: "/banners/",
      params: {
        campaign: id,
        page_size: 5,
      },
    });
    if (bannersPreview?.results.length) {
      setCampaignPreview(bannersPreview.results);
      setOpenedPopover({ openedId: id, anchorEl: currentTarget });
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={horizontal ? 4 : 12}>
        <TextField
          m={2}
          id="campaign"
          label="Type to Filter Campaign"
          type="search"
          value={filters.campaign}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <Tooltip title="Refresh">
                    <LoopIcon
                      ml="auto"
                      // color="primary"
                      cursor="pointer"
                      onClick={() =>
                        refetchCampaigns({
                          url: "/campaigns/",
                          params: {
                            page: 1,
                            page_size: 15,
                            search: filters.campaign,
                            in_country:
                              countryFilter === "all" ? "" : countryFilter,
                          },
                        })
                      }
                    />
                  </Tooltip>
                  <Divider className={classes.divider} orientation="vertical" />
                  <Tooltip title="Search">
                    <SortIcon
                      ml="auto"
                      // color="primary"
                      cursor="pointer"
                      onClick={() => setOpenSearchCampaigns(true)}
                    />
                  </Tooltip>
                  {/*<Divider className={classes.divider} orientation="vertical" />*/}
                  {/*<Tooltip title="Add">*/}
                  {/*  <AddCircle*/}
                  {/*    color="primary"*/}
                  {/*    cursor="pointer"*/}
                  {/*    onClick={() => setOpenCampaigns(true)}*/}
                  {/*  />*/}
                  {/*</Tooltip>*/}
                </InputAdornment>
                <SearchCampaign
                  open={openSearchCampaigns}
                  setOpen={setOpenSearchCampaigns}
                  handleSearch={handleSearchCampaign}
                  countryFilter={countryFilter}
                />
              </>
            ),
          }}
        />
        <EnhancedTable
          rows={campaigns?.results}
          name="campaignSelect"
          selected={data.campaignSelect}
          handleClick={handleClickCampaign}
          page={pagination.campaign}
          loadData={(p) =>
            setPagination((prevState) => ({ ...prevState, campaign: p }))
          }
          showPagination
          count={campaigns?.count}
          subName="advertiser"
          onHoverEnter={handlePopoverOpen}
          onHoverLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.popoverContent,
          }}
          open={!!openedPopover.openedId}
          anchorEl={openedPopover.anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: invertPopoverPos ? "right" : "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: invertPopoverPos ? "left" : "right",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Paper className={classes.paper}>
            <Masonry columns={[1, 2]}>
              {campaignPreview &&
                campaignPreview.map((banner) => {
                  return (
                    <ProgressiveImageSize
                      src={
                        banner.type.indexOf("video") !== -1
                          ? banner.thumb
                          : banner.media
                      }
                      alt={banner.id}
                      size={banner.size}
                      type={banner.type}
                      key={banner.id}
                    />
                  );
                })}
            </Masonry>
          </Paper>
        </Popover>
      </Grid>
      <Grid item xs={horizontal ? 4 : 12}>
        <TextField
          m={2}
          id="product"
          label="Type to Filter Product"
          type="search"
          onChange={handleChange}
          value={filters.product}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <Tooltip title="Refresh">
                    <LoopIcon
                      ml="auto"
                      // color="primary"
                      cursor="pointer"
                      onClick={() => {
                        setFilters((prevState) => ({
                          ...prevState,
                          product: "",
                        }));
                        setProductsBy("category");
                      }}
                    />
                  </Tooltip>
                </InputAdornment>
              </>
            ),
          }}
        />
        <EnhancedTable
          rows={data.category || data.productSelect ? products?.results : []}
          name="productSelect"
          selected={data.productSelect}
          handleClick={handleClick}
          rowsPerPage={10}
          page={data.category || data.productSelect ? pagination.product : 1}
          loadData={(p) =>
            setPagination((prevState) => ({ ...prevState, product: p }))
          }
          showPagination
          count={data.category || data.productSelect ? products?.count : 0}
          subName="category"
        />
      </Grid>
      <Grid item xs={horizontal ? 4 : 12}>
        <TextField
          m={2}
          id="model"
          label="Type to Filter Models"
          type="search"
          onChange={handleChange}
          value={filters.model}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <Tooltip title="Refresh">
                    <LoopIcon
                      ml="auto"
                      // color="primary"
                      cursor="pointer"
                      onClick={() => refetchModels()}
                    />
                  </Tooltip>
                </InputAdornment>
              </>
            ),
          }}
        />
        <EnhancedTable
          rows={
            (data.brandSelect && data.productSelect) || data.modelSelect
              ? models?.results
              : []
          }
          name="modelSelect"
          selected={data.modelSelect}
          handleClick={handleClick}
          rowsPerPage={10}
          page={
            (data.brandSelect && data.productSelect) || data.modelSelect
              ? pagination.model
              : 1
          }
          loadData={(p) =>
            setPagination((prevState) => ({ ...prevState, model: p }))
          }
          showPagination
          count={
            (data.brandSelect && data.productSelect) || data.modelSelect
              ? models?.count
              : 0
          }
        />
      </Grid>
    </Grid>
  );
}

export default AssignCampaign;
